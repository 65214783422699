<template>
  <div ref="form_container" class="card position-relative mb-0" v-on:keyup.enter="save_model()">
    <spinner v-if="loading" />
    <div class="card-body p-0" >

      <tc-form
        :feedbacks="mb.statics('Payments').feedbacks"
        :vobject="$v"
        nested="payments"
        ref="form"
        class="form-row"
      >
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>_id<span class='required_field'>*</span></label>
        <tc-input placeholder='Type the value' name='_id' v-model="payments._id"></tc-input>
      </tc-form-item>
        <tc-form-item class="form-group mb-0 col-md-6 px-3">
          <label>CreatedAt</label>
          <div class="d-flex flex-row">
            <tc-date-picker name="createdAt" v-model="payments.createdAt"></tc-date-picker>
          </div>
        </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>Nonce</label>
        <tc-input placeholder='Type the value' name='nonce' v-model="payments.nonce"></tc-input>
      </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>Total</label>
        <tc-input placeholder='Type the value'   type_car='num'  name='total' v-model="payments.total"></tc-input>
      </tc-form-item>
        <tc-form-item class="form-group mb-0 col-md-6 px-3">
          <label>Orders</label>
          <div class="d-flex flex-row">
            <tc-autocomplete
              placeholder="Seleccione el Orders"
              name="order_id"
              ref="select_order"
              :successFeed="false"
              :defaultValue="payments.order_id"
              v-model="payments.order_id"
              :url="mb.statics('Orders').select_2_url"
            />
            <a-button type="dashed"
                      icon="plus"
                      class="dashed-primary rounded mt-1 ml-2"
                      @click="openModalCreateorder">
            </a-button>
          </div>
        </tc-form-item>

        <a-modal
          @cancel="showModalCreateorder = false"
          v-if="showModalCreateorder"
          class="modal-form"
          :title="'Añadir orders'"
          :visible="true"
          :header="null"
          :footer="null"
          :maskClosable="false"
        >
          <orders_form :model="null" :modal="true" @close_modal="orderAdded"/>
        </a-modal>
      <tc-form-item class="form-group p-0 col-12">
        <label>PaymentDetails</label>
        <tc-input :text_area="true" size="large" name="paymentDetails" v-model="payments.paymentDetails"/>
      </tc-form-item>
      </tc-form>
    </div>
    <div class="card-footer p-0">
      <button :disabled="loading" class="btn btn-primary" @click="save_model()">{{button_text}}</button>
      <button v-if="!payments.get_id()" :disabled="loading" class="btn btn-primary" @click="save_model(true)">Añadir y nuevo</button>
      <button :disabled="loading" class="btn btn-danger" @click="cancel()">Cancel</button>
    </div>
  </div>
</template>

<script>
import Payments from "../../../../../entities/models/modules/managment/payments.model";
import * as utils from "../../../../../entities/utils/utils";
import * as mb from "../../../../../entities/models"
  import Orders_form from '../../orders/form/orders_form';

export default {
  name: "payments_form",
  inject: {
      close_modal: { default: ()=>{} },
      load_data: {default: () => {} }
  },
  props: {
    model: {
      type: Object,
      default: () => {}
    },
      modal: {
        type: Boolean,
        default: false
      },
    popoverPlacement: {
      type: String,
      default: "bottomLeft"
    }
  },
  validations: mb.statics('Payments').validations,
  data() {
    return {
      loading: false,
      mb,      // This property is for load static or instance class
      payments: mb.instance( 'Payments'),
      showModalCreateorder: false,
      orders_list: [],
    };
  },
  computed: {
    paymentsFeedbacks() {
      return mb.statics('Payments').feedbacks;
    },
    button_text() {
      return this.payments.get_id() ? "Actualizar" : "Añadir";
    },
  },

  mounted: function() {
    this.payments = mb.instance( 'Payments',this.model);

  },
  components: {

       Orders_form,
             },
  methods: {
      openModalCreateorder() {
        this.showModalCreateorder = true;
      },
      orderAdded(refresh) {
        this.showModalCreateorder = false;
        refresh?this.refreshorder():'';
      },
      async refreshorder() {
        this.loading = true;
        await this.$refs.select_order.load();
        this.loading = false;
      },
      cancel(){
        if (!this.model) {
          this.$emit('close_modal',false)
        } else {
        this.modal?this.close_modal(null,false):this.$router.push({name: 'payments_list'})
       }
      },
    save_model(and_new=false) {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const accion=this.payments.get_id() ? "actualizado" : "añadido";
        this.payments
          .save()
          .then((response) => {
            if(utils.process_response(response,accion)) {
              if (!this.model && !and_new && this.modal) {

                  this.$emit('close_modal',true)
                  return;
               }
                else {
                   !and_new?this.modal?this.close_modal(null,true):this.$router.push({name: 'payments_list'}):this.payments=mb.instance('Payments');               }
               this.load_data()
               this.$refs.form.vobject.$reset()

            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            utils.process_error(error);
          });
      }
    }
  }
};
</script>

<style scoped>
@import "payments_form.css";
</style>

