/**Generate by ASGENS
 *@author Charlietyn
 *@date Thu Aug 26 11:43:49 GMT-04:00 2021
 *@time Thu Aug 26 11:43:49 GMT-04:00 2021
 */
import {
    required,
    integer,
    between,
    maxLength,
    minLength,
    decimal
} from 'vuelidate/lib/validators';

import BaseModel from '../../base.model';

import moment from 'moment';
import {DateTime} from "luxon";

const url = '1/order/list';

export default class Orders extends BaseModel {

    _id
    order_init_time
    is_complete
    latitude
    longitude
    type
    braintree
    tz
    status
    base_price
    vet_fee
    client
    partner
    paid
    order_end_time

    constructor(attributes = null) {
        super();
        if (attributes != null) {

            this._id = attributes._id || undefined
            this.order_init_time = attributes.order_init_time ? moment(attributes.order_init_time).format('DD-MM-YYYY h:mm a') : null
            this.order_end_time = attributes.order_end_time ? moment(attributes.order_end_time).format('DD-MM-YYYY h:mm a') : null
            this.is_complete = attributes.is_complete == '1' ? true : false
            this.latitude = attributes.latitude || null
            this.longitude = attributes.longitude || null
            this.type = attributes.type || null
            this.braintree = attributes.braintree || null
            this.tz = attributes.tz || null
            this.status = attributes.status || null
            this.base_price = attributes.base_price || null
            this.vet_fee = attributes.vet_fee || null
            this.client = attributes.client || null
            this.partner = attributes.partner || null
            this.paid = attributes.paid || false
        }
    }

    set_attributes(attributes = null) {
        if (attributes != null) {

            this._id = attributes._id
            this.order_init_time = moment(attributes.order_init_time).format('DD-MM-YYYY h:mm a')
            this.order_end_time = moment(attributes.order_end_time).format('DD-MM-YYYY h:mm a')
            this.is_complete = attributes.is_complete
            this.latitude = attributes.latitude
            this.longitude = attributes.longitude
            this.type = attributes.type
            this.braintree = attributes.braintree
            this.tz = attributes.tz
            this.status = attributes.status
            this.base_price = attributes.base_price
            this.vet_fee = attributes.vet_fee
            this.client = attributes.client
            this.partner = attributes.partner
            this.paid = attributes.paid
        }
    }

    static validations = {
        orders: {
            _id: {
                required,
            },
            order_init_time: {},
            is_complete: {},
            latitude: {
                decimal,
            },
            longitude: {
                decimal,
            },
            type: {
                integer,
            },
            braintree: {},
            tz: {
                maxLength: maxLength(65535),
            },
            status: {},
            base_price: {},
            vet_fee: {
                decimal,
            },
            client: {},
            partner: {},
        },
    }

    static feedbacks = {
        orders: {
            _id: {
                isUnique: 'This _id has been taken'

            },
        },
    }

    static columns = [
        {
            title: 'Id',
            dataIndex: '_id',
            align: 'center',
            scopedSlots: {
                customRender: 'ordenid'
            },
            key: '_id',
            width: '25%',
            sorter: (a, b) => (a._id > b._id) - (a._id < b._id)
        },
        {
            title: 'Created',
            dataIndex: 'order_init_time',
            align: 'center',
            key: 'order_init_time',
            width: '18%',
            customRender: function (value) {
                return DateTime.fromISO(value).toFormat('dd-MM-yyyy h:mm a')
            },
            use_filter: true,
            sorter: (a, b) => a.order_init_time - b.order_init_time
        },
        {
            title: 'Ended',
            dataIndex: 'order_end_time',
            align: 'center',
            key: 'order_end_time',
            width: '18%',
            customRender: function (value) {
                return DateTime.fromISO(value).toMillis() !== 18000000 ? DateTime.fromISO(value).toFormat('dd-MM-yyyy h:mm a') : "No finalizada"
            },
            use_filter: true,
            sorter: (a, b) => DateTime.fromISO(a.order_end_time).toMillis() - DateTime.fromISO(b.order_end_time).toMillis()
        },
        {
            title: 'Complete',
            dataIndex: 'is_complete',
            align: 'center',
            key: 'is_complete',
            sorter: (a, b) => a.is_complete - b.is_complete,
            width: '10%',

        },
        {
            title: 'Paid',
            scopedSlots: {
                customRender: 'paid'
            },
            align: 'center',
            key: 'paid',
            width: '10%',
            sorter: (a, b) => a.paid - b.paid
        },
        {
            title: 'Latitude',
            dataIndex: 'latitude',
            align: 'center',
            key: 'latitude',

        },
        {
            title: 'Longitude',
            dataIndex: 'longitude',
            align: 'center',
            key: 'longitude',

        },
        {
            title: 'Type',
            dataIndex: 'type',
            align: 'center',
            key: 'type',

            sorter: (a, b) => a.type - b.type
        },
        {
            title: 'Braintree',
            dataIndex: 'braintree',
            align: 'center',
            key: 'braintree',

            sorter: (a, b) => (a.braintree > b.braintree) - (a.braintree < b.braintree)
        },
        {
            title: 'Tz',
            dataIndex: 'tz',
            align: 'center',
            key: 'tz',

            sorter: (a, b) => (a.tz > b.tz) - (a.tz < b.tz)
        },
        {
            title: 'Status',
            scopedSlots: {
                customRender: 'status'
            },
            use_filter: true,
            align: 'center',
            width: '10%',
            key: 'status',
            sorter: (a, b) => (a.status > b.status) - (a.status < b.status)
        },
        {
            title: 'Base price',
            dataIndex: 'base_price',
            align: 'center',
            key: 'base_price',
            use_filter: true,
            sorter: (a, b) => (a.base_price > b.base_price) - (a.base_price < b.base_price)
        },
        {
            title: 'Vet Fee',
            dataIndex: 'vet_fee',
            align: 'center',
            key: 'vet_fee',
            width: '9%',
            sorter: (a, b) => a.vet_fee - b.vet_fee
        },
        {
            title: 'Client',
            dataIndex: "client_name",
            use_filter: true,
            align: 'center',
            key: 'client_name',
            width: '20%',

            sorter: (a, b) => a.client_name && b.client_name ? (a.client_name > b.client_name) - (a.client_name < b.client_name) : 0
        },
        {
            title: 'Partner',
            dataIndex: "partner_name",
            use_filter: true,
            align: 'center',
            key: 'partner_name',
            width: '20%',

            sorter: (a, b) => a.partner_name && b.partner_name ? (a.partner_name > b.partner_name) - (a.partner_name < b.partner_name) : 0
        },
        {
            title: 'Actions',
            key: 'action_elements',
            fixed: 'right',
            scopedSlots: {
                customRender: 'action'
            }
        }
    ];
    static columns_simple = [
        {
            title: 'Id',
            dataIndex: '_id',
            align: 'center',
            key: '_id',
            width: '40%',
            sorter: (a, b) => (a._id > b._id) - (a._id < b._id)
        },
        {
            title: 'Created',
            dataIndex: 'order_init_time',
            align: 'center',
            key: 'order_init_time',
            width: '25%',
            customRender: function (value) {
                return DateTime.fromISO(value).toFormat('dd-MM-yyyy h:mm')
            },
            use_filter: true,
            sorter: (a, b) => a.order_init_time - b.order_init_time
        },
        {
            title: 'Ended',
            dataIndex: 'order_end_time',
            align: 'center',
            key: 'order_end_time',
            width: '25%',
            customRender: function (value) {
                return DateTime.fromISO(value).toMillis() !== 18000000 ? DateTime.fromISO(value).toFormat('dd-MM-yyyy h:mm') : "No finalizada"
            },
            use_filter: true,
            sorter: (a, b) => DateTime.fromISO(a.order_end_time).toMillis() - DateTime.fromISO(b.order_end_time).toMillis()
        },
        {
            title: 'Status',
            scopedSlots: {
                customRender: 'status'
            },
            use_filter: true,
            align: 'center',
            key: 'status',
            sorter: (a, b) => (a.status > b.status) - (a.status < b.status)
        },
    ];

    static get url() {
        return url
    };

    get url() {
        return url
    };

    get_id() {
        return this._id;
    }

    class_name() {
        return 'Orders'
    }


}

