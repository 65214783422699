<template>
  <div>
    <div>
      <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
        <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <!--begin::Info-->
          <div class="d-flex align-items-center flex-wrap mr-1">
            <!--begin::Page Heading-->
            <div class="d-flex align-items-baseline flex-wrap mr-5 mb-1">
              <!--begin::Page Title-->
              <h5 class="text-dark font-weight-bold my-1 mr-5" style="color: #9d1352">Orders</h5>
              <!--end::Page Title-->
              <!--begin::Breadcrumb-->
              <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
                <li class="breadcrumb-item">
                  <router-link :to="{name:'index'}" class="text-muted">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                  <a href="javascript:void(0)" class="text-muted">Managment</a>
                </li>
                <li class="breadcrumb-item">
                  <router-link :to="{name:'orders_list'}" class="text-muted">Order List</router-link>
                </li>
                <li class="breadcrumb-item">
                  <a href="javascript:void(0)" class="text-muted">Orders view</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-body">
      <h2 class="section-title">Order Information</h2>
      <p class="section-lead"></p>

      <div class="row mt-5">
        <div class="col-12 col-sm-8 col-lg-6">
          <div class="card chat-box card-success" id="mychatbox2">
            <div class="card-header">
              <h4><i class="fas fa-circle text-success mr-2" title="" data-toggle="tooltip"
                     data-original-title="Online"></i> Chat of order {{ this.orders._id }}</h4>
            </div>
            <a-spin :spinning="loadingfb" tip="Loading from firebase...">
              <div class="card-body chat-content" tabindex="3" style="overflow: hidden; outline: none;">
                <div :class="'chat-item chat-'+side(item.id)" v-for="(item,index) in chatfirebase" :key="index"
                     style=""><img
                    src="/static/assets/img/avatar/client.jpg">
                  <div class="chat-details">
                    <div class="chat-text">{{ item.message }}</div>
                    <div class="chat-time">{{ item.date }}</div>
                  </div>
                </div>
                <p v-if="nochat" class="mt-5 blockquote-footer blockquote text-muted" style="font-size: 30px">There's no chat in this order</p>
              </div>
            </a-spin>
            <div class="card-footer chat-form">

            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4 col-lg-6">
          <div class="card">
            <div class="card-header">
              <h4><i class="fas fa-circle text-success mr-2" title="" data-toggle="tooltip"
                     data-original-title="Online"></i> Order Data</h4>
            </div>
            <div class="collapse show" id="mycard-collapse" style="">
              <div class="card-body">
                <ul class="list-group">
                  <li class="list-group-item">Client: <span class="text-muted">{{ client_name }}</span></li>
                  <li class="list-group-item">Partner: <span class="text-muted">{{ partner_name }}</span></li>
                  <li class="list-group-item">Init: <span class="text-muted">{{ orders.order_init_time }}</span></li>
                  <li class="list-group-item">Ended: <span class="text-muted">{{ orders.order_end_time }}</span></li>
                  <li class="list-group-item">
                    <span v-if="status==='done'" class="badge badge-success">{{
                        orders.status
                      }}</span>
                    <span v-else-if="status==='created'" class="badge badge-info">{{
                        orders.status
                      }}</span>
                    <span v-else-if="status==='canceled'"
                          class="badge badge-danger">{{ orders.status }}</span>
                    <span v-else class="badge badge-dark">{{ orders.status }}</span>
                  </li>
                  <li class="list-group-item">
                    <span v-if="paid" class="badge badge-success">Paid</span>
                    <span v-else class="badge badge-danger">Unpaid</span>
                  </li>
                </ul>
              </div>
              <div class="card-footer">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Orders from "../../../../../entities/models/modules/managment/orders.model";
import * as mb from "../../../../../entities/models"
import Vue from "vue";
import * as utils from './../../../../../entities/utils/utils'

export default {
  name: "orders_view",
  inject: {
    close_modal: {
      default: () => {
      }
    },
    load_data: {
      default: () => {
      }
    }
  },
  props: {
    model: {
      type: Object,
      default: () => {
      }
    },
    modal: {
      type: Boolean,
      default: false
    },
    popoverPlacement: {
      type: String,
      default: "bottomLeft"
    }
  },
  validations: mb.statics('Orders').validations,
  data() {
    return {
      loading: false,
      loadingfb: false,
      mb,      // This property is for load static or instance class
      orders: mb.instance('Orders'),
      chat: [],
      nochat: false,
      entries: [],
      partner: mb.instance('Partners'),
      client: mb.instance('Clients'),
      showModalCreateclient: false,
      clients_list: [],
      showModalCreatepartner: false,
      partners_list: [],
    };
  },
  computed: {
    chatfirebase() {
      return this.chat || []
    },
    client_name() {
      return this.client.first_name ? this.client.first_name + ' ' + this.client.last_name : 'No Client'
    },
    partner_name() {
      return this.partner.first_name ? this.partner.first_name + ' ' + this.partner.last_name : 'No Partner'
    },
    status() {
      return this.orders ? this.orders.status : ''
    },
    paid() {
      return this.orders ? this.orders.pais : ''
    },

    ordersFeedbacks() {
      return mb.statics('Orders').feedbacks;
    },
    button_text() {
      return this.orders.get_id() ? "Actualizar" : "Añadir";
    },
  },

  mounted: async function () {

    if (this.$route.params.id != null) {
      this.$store.site.loading = true
      const response = await mb.statics('Orders').custom('get', '../' + this.$route.params.id + '/full').catch(error => {
        this.$router.push({name: "orders_list"})
      })
      const fb = this.$store.firebase.getChatDB();
      this.loadingfb = true;
      //Example chat11271
      fb.child('chat'+this.$route.params.id).on('value', snapshot => {
        if (snapshot.val()) {
          this.chat = Object.values(snapshot.val())
          this.entries = Object.keys(utils.groupby(this.chat, 'id'))
        } else {
          this.nochat = true
        }
        this.loadingfb = false
      });
      this.orders = await mb.instance('Orders', response.data);
      await mb.statics('Clients').view(this.orders.client).then(response => {
        this.client = mb.instance('Clients', response.data)
      }).catch(error => {
        this.$store.site.loading = false
      })
      await mb.statics('Partners').view(this.orders.partner).then(response => {
        this.partner = mb.instance('Partners', response.data)
      }).catch(error => {
        this.$store.site.loading = false
      })

      this.$store.site.loading = false
    } else {
      this.$router.push({name: "orders_list"})
    }

  },
  components: {},
  async beforeMount() {
    await Promise.all([
      Vue.loadScript('/static/assets/js/page/components-chat-box.js'),
    ])

  },
  methods: {
    side(key) {
      return key === this.entries[0] ? 'left' : 'right'
    }
  }
};
</script>

<style scoped>
@import "orders_form.css";

.chat-left .chat-text, .chat-left .chat-time {
  margin-left: -40px;
}

.chat-box .chat-content .chat-item .chat-details {
  margin-left: 90px;
}
</style>

