<template>
  <div ref="form_container" class="card position-relative mb-0" v-on:keyup.enter="save_model()">
    <spinner v-if="loading" />
    <div class="card-body p-0" >

      <tc-form
        :feedbacks="mb.statics('Offers').feedbacks"
        :vobject="$v"
        nested="offers"
        ref="form"
        class="form-row"
      >
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>_id<span class='required_field'>*</span></label>
        <tc-input placeholder='Type the value' name='_id' v-model="offers._id"></tc-input>
      </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>Vet_fee</label>
        <tc-input placeholder='Type the value'   type_car='num'  name='vet_fee' v-model="offers.vet_fee"></tc-input>
      </tc-form-item>
        <tc-form-item class="form-group mb-0 col-md-6 px-3">
          <label>Created_At</label>
          <div class="d-flex flex-row">
            <tc-date-picker name="created_At" v-model="offers.created_At"></tc-date-picker>
          </div>
        </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>Status</label>
        <tc-input placeholder='Type the value' name='status' v-model="offers.status"></tc-input>
      </tc-form-item>
      <tc-form-item class="form-group p-0 col-12">
        <label>Tecs</label>
        <tc-input :text_area="true" size="large" name="tecs" v-model="offers.tecs"/>
      </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>TimeUntil</label>
        <tc-input placeholder='Type the value'   type_car='num'  name='timeUntil' v-model="offers.timeUntil"></tc-input>
      </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>TimeFrom</label>
        <tc-input placeholder='Type the value'   type_car='num'  name='timeFrom' v-model="offers.timeFrom"></tc-input>
      </tc-form-item>
        <tc-form-item class="form-group mb-0 col-md-6 px-3">
          <label>Housecalls</label>
          <div class="d-flex flex-row">
            <tc-autocomplete
              placeholder="Seleccione el Housecalls"
              name="hc_id"
              ref="select_hc"
              :successFeed="false"
              :defaultValue="offers.hc_id"
              v-model="offers.hc_id"
              :url="mb.statics('Housecalls').select_2_url"
            />
            <a-button type="dashed"
                      icon="plus"
                      class="dashed-primary rounded mt-1 ml-2"
                      @click="openModalCreatehc">
            </a-button>
          </div>
        </tc-form-item>

        <a-modal
          @cancel="showModalCreatehc = false"
          v-if="showModalCreatehc"
          class="modal-form"
          :title="'Añadir housecalls'"
          :visible="true"
          :header="null"
          :footer="null"
          :maskClosable="false"
        >
          <housecalls_form :model="null" :modal="true" @close_modal="hcAdded"/>
        </a-modal>
        <tc-form-item class="form-group mb-0 col-md-6 px-3">
          <label>Partners</label>
          <div class="d-flex flex-row">
            <tc-autocomplete
              placeholder="Seleccione el Partners"
              name="vet_id"
              ref="select_vet"
              :successFeed="false"
              :defaultValue="offers.vet_id"
              v-model="offers.vet_id"
              :url="mb.statics('Partners').select_2_url"
            />
            <a-button type="dashed"
                      icon="plus"
                      class="dashed-primary rounded mt-1 ml-2"
                      @click="openModalCreatevet">
            </a-button>
          </div>
        </tc-form-item>

        <a-modal
          @cancel="showModalCreatevet = false"
          v-if="showModalCreatevet"
          class="modal-form"
          :title="'Añadir partners'"
          :visible="true"
          :header="null"
          :footer="null"
          :maskClosable="false"
        >
          <partners_form :model="null" :modal="true" @close_modal="vetAdded"/>
        </a-modal>
      </tc-form>
    </div>
    <div class="card-footer p-0">
      <button :disabled="loading" class="btn btn-primary" @click="save_model()">{{button_text}}</button>
      <button v-if="!offers.get_id()" :disabled="loading" class="btn btn-primary" @click="save_model(true)">Añadir y nuevo</button>
      <button :disabled="loading" class="btn btn-danger" @click="cancel()">Cancel</button>
    </div>
  </div>
</template>

<script>
import Offers from "../../../../../entities/models/modules/managment/offers.model";
import * as utils from "../../../../../entities/utils/utils";
import * as mb from "../../../../../entities/models"
  import Housecalls_form from '../../housecalls/form/housecalls_form';
  import Partners_form from '../../partners/form/partners_form';

export default {
  name: "offers_form",
  inject: {
      close_modal: { default: ()=>{} },
      load_data: {default: () => {} }
  },
  props: {
    model: {
      type: Object,
      default: () => {}
    },
      modal: {
        type: Boolean,
        default: false
      },
    popoverPlacement: {
      type: String,
      default: "bottomLeft"
    }
  },
  validations: mb.statics('Offers').validations,
  data() {
    return {
      loading: false,
      mb,      // This property is for load static or instance class
      offers: mb.instance( 'Offers'),
      showModalCreatehc: false,
      housecalls_list: [],
      showModalCreatevet: false,
      partners_list: [],
    };
  },
  computed: {
    offersFeedbacks() {
      return mb.statics('Offers').feedbacks;
    },
    button_text() {
      return this.offers.get_id() ? "Actualizar" : "Añadir";
    },
  },

  mounted: function() {
    this.offers = mb.instance( 'Offers',this.model);

  },
  components: {

       Housecalls_form,
       Partners_form,
             },
  methods: {
      openModalCreatehc() {
        this.showModalCreatehc = true;
      },
      hcAdded(refresh) {
        this.showModalCreatehc = false;
        refresh?this.refreshhc():'';
      },
      async refreshhc() {
        this.loading = true;
        await this.$refs.select_hc.load();
        this.loading = false;
      },
      openModalCreatevet() {
        this.showModalCreatevet = true;
      },
      vetAdded(refresh) {
        this.showModalCreatevet = false;
        refresh?this.refreshvet():'';
      },
      async refreshvet() {
        this.loading = true;
        await this.$refs.select_vet.load();
        this.loading = false;
      },
      cancel(){
        if (!this.model) {
          this.$emit('close_modal',false)
        } else {
        this.modal?this.close_modal(null,false):this.$router.push({name: 'offers_list'})
       }
      },
    save_model(and_new=false) {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const accion=this.offers.get_id() ? "actualizado" : "añadido";
        this.offers
          .save()
          .then((response) => {
            if(utils.process_response(response,accion)) {
              if (!this.model && !and_new && this.modal) {

                  this.$emit('close_modal',true)
                  return;
               }
                else {
                   !and_new?this.modal?this.close_modal(null,true):this.$router.push({name: 'offers_list'}):this.offers=mb.instance('Offers');               }
               this.load_data()
               this.$refs.form.vobject.$reset()

            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            utils.process_error(error);
          });
      }
    }
  }
};
</script>

<style scoped>
@import "offers_form.css";
</style>

