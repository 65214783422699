/**Generate by ASGENS
 *@author Charlietyn
 *@date Sun Aug 29 21:25:56 GMT-04:00 2021
 *@time Sun Aug 29 21:25:56 GMT-04:00 2021
 */
import {
    required,
    integer,
    between,
    maxLength,
    minLength,
    decimal
} from 'vuelidate/lib/validators';

import BaseModel from '../../base.model';
import axios from "../../../../config/axios/axios";

const url = '1/partner/topay';

export default class Partner_to_pay extends BaseModel {

    _id
    name
    paypal
    total_orders
    total_pay

    constructor(attributes = null) {
        super();
        if (attributes != null) {

            this._id = attributes._id || undefined
            this.name = attributes.name || null
            this.paypal = attributes.paypal || null
            this.total_orders = attributes.total_orders || null
            this.total_pay = attributes.total_pay || null
        }
    }

    set_attributes(attributes = null) {
        if (attributes != null) {

            this._id = attributes._id
            this.name = attributes.name
            this.paypal = attributes.paypal
            this.total_orders = attributes.total_orders
            this.total_pay = attributes.total_pay
        }
    }

    static validations = {
        partner_to_pay: {
            _id: {
                required,
            },
            name: {},
            paypal: {},
            total_orders: {
                integer,
            },
            total_pay: {
                decimal,
            },
        },
    }

    static feedbacks = {
        partner_to_pay: {
            _id: {
                isUnique: 'This _id has been taken'

            },
        },
    }

    static columns = [
        {
            title: 'Id',
            dataIndex: '_id',
            align: 'center',
            key: '_id',
            width: '20%',
            sorter: (a, b) => (a._id > b._id) - (a._id < b._id)
        },
        {
            title: 'Partner Name',
            dataIndex: 'name',
            align: 'center',
            key: 'name',
            width: '30%',
            sorter: (a, b) => (a.name > b.name) - (a.name < b.name)
        },
        {
            title: 'Paypal Email',
            dataIndex: 'paypal',
            align: 'center',
            key: 'paypal',
            width: '20%',
            sorter: (a, b) => (a.paypal > b.paypal) - (a.paypal < b.paypal)
        },
        {
            title: 'Total Orders',
            dataIndex: 'total_orders',
            align: 'center',
            key: 'total_orders',
            width: '14%',
            sorter: (a, b) => a.total_orders - b.total_orders
        },
        {
            title: 'To pay',
            dataIndex: 'total_pay',
            align: 'center',
            key: 'total_pay',
            width: '10%'
        },
        {
            title: 'Acciones',
            key: 'action_elements',
            scopedSlots: {
                customRender: 'action'
            }
        }
    ];

    static get url() {
        return url
    };

    get url() {
        return url
    };

    get_id() {
        return this._id;
    }

    class_name() {
        return 'Partner_to_pay'
    }

    static paid(params) {
        return axios.post('1/partner/paid', params).then((response) => {
            return response
        }).catch(error => {
            throw JSON.parse(JSON.stringify(error))
        })
    }


}

