<template>
  <div class="row">
    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
      <div class="card card-statistic-1">
        <div class="card-icon bg-primary">
          <i class="far fa-user"></i>
        </div>
        <div class="card-wrap">
          <div class="card-header">
            <h4>Total de Partners</h4>
          </div>
          <div class="card-body">
            {{ partners }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
      <div class="card card-statistic-1">
        <div class="card-icon bg-danger">
          <i class="far fa-newspaper"></i>
        </div>
        <div class="card-wrap">
          <div class="card-header">
            <h4>Clients</h4>
          </div>
          <div class="card-body">
            {{ clients }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
      <div class="card card-statistic-1">
        <div class="card-icon bg-warning">
          <i class="far fa-file"></i>
        </div>
        <div class="card-wrap">
          <div class="card-header">
            <h4>Orders</h4>
          </div>
          <div class="card-body">
            {{ orders }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
      <div class="card card-statistic-1">
        <div class="card-icon bg-success">
          <i class="fas fa-money-bill"></i>
        </div>
        <div class="card-wrap">
          <div class="card-header">
            <h4>Pagos</h4>
          </div>
          <div class="card-body">
            {{ payments }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    name: 'count_elements',
    props:{},
    components: {},
    data() {
      return {
        orders: 0,
        clients: 0,
        partners: 0,
        payments: 0,
      }
    },
    methods: {},
    computed: {},
    watch: {},
    async beforeMount() {
      const result = await this.$store.site.count_report();
      this.orders = result.data.count_orden
      this.clients = result.data.count_clients
      this.partners = result.data.count_partners
      this.payments = result.data.count_payments
    }
  }
</script>

<style scoped>

</style>

