<template>
    <footer class="main-footer">
        <div class="footer-left">
            <div class=""></div>  <a href="www.kwikvet.com" style="color: #9d1352">Kwikvet {{year}}</a>
        </div>
        <div class="footer-right">

        </div>
    </footer>
</template>

<script>
  export default {
    name: 'Footer_ban',
    data () {
      return {
        year:new Date().getFullYear()
      }
    },
    methods: {},
    computed: {},
    watch:{},
    beforeMount () {
    }
  }
</script>

<style scoped>

</style>
