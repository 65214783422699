import Vue from 'vue'
import Router from 'vue-router'
import index from '../../components/views/site/index'

Vue.use(Router)

export default new Router({
    mode: 'history',
    hash: false,
    routes: [
        {
            path: '/',
            name: 'index',
            component: index,
            props: {page: 1}
        },
        {
            path: '*',
            redirect: '/'
        },
        {
            path: '/managment/clients_list',
            name: 'clients_list',
            component: require('../../components/views/managment/clients/list/clients_list').default,
            props: {page: 1}
        },
        {
            path: '/managment/clients_form/:id',
            name: 'clients_form',
            component: require('../../components/views/managment/clients/form/clients_form').default,
            props: {page: 1}
        },
        {
            path: '/managment/housecalls_list',
            name: 'housecalls_list',
            component: require('../../components/views/managment/housecalls/list/housecalls_list').default,
            props: {page: 1}
        },
        {
            path: '/managment/housecalls_map',
            name: 'housecalls_map',
            component: require('../../components/views/managment/housecalls/list/housecalls_map').default,
            props: {page: 1}
        },
        {
            path: '/managment/housecalls_form/:id',
            name: 'housecalls_form',
            component: require('../../components/views/managment/housecalls/form/housecalls_form').default,
            props: {page: 1}
        },
        {
            path: '/managment/offers_list',
            name: 'offers_list',
            component: require('../../components/views/managment/offers/list/offers_list').default,
            props: {page: 1}
        },
        {
            path: '/managment/offers_form',
            name: 'offers_form',
            component: require('../../components/views/managment/offers/form/offers_form').default,
            props: {page: 1}
        },
        {
            path: '/managment/orders_list',
            name: 'orders_list',
            component: require('../../components/views/managment/orders/list/orders_list').default,
            props: {page: 1}
        },
        {
            path: '/managment/orders_form',
            name: 'orders_form',
            component: require('../../components/views/managment/orders/form/orders_form').default,
            props: {page: 1}
        },
        {
            path: '/managment/partners_list',
            name: 'partners_list',
            component: require('../../components/views/managment/partners/list/partners_list').default,
            props: {page: 1}
        },
        {
            path: '/managment/partners_form/:id',
            name: 'partners_form',
            component: require('../../components/views/managment/partners/form/partners_form').default,
            props: {page: 1}
        },
        {
            path: '/managment/payments_list',
            name: 'payments_list',
            component: require('../../components/views/managment/payments/list/payments_list').default,
            props: {page: 1}
        },
        {
            path: '/managment/payments_form',
            name: 'payments_form',
            component: require('../../components/views/managment/payments/form/payments_form').default,
            props: {page: 1}
        },
        {
            path: '/managment/partner_to_pay_list',
            name: 'partner_to_pay_list',
            component: require('../../components/views/managment/partner_to_pay/list/partner_to_pay_list').default,
            props: {page: 1}
        },
        {
            path: '/managment/partner_to_pay_form',
            name: 'partner_to_pay_form',
            component: require('../../components/views/managment/partner_to_pay/form/partner_to_pay_form').default,
            props: {page: 1}
        },
        {
            path: '/managment/order/:id',
            name: 'orders_view',
            component: require('../../components/views/managment/orders/form/orders_view').default,
            props: {page: 1}
        },
    ]
})

