<template>
  <div ref="form_container" class="card position-relative mb-0" v-on:keyup.enter="save_model()">
    <spinner v-if="loading" />
    <div class="card-body p-0" >

      <tc-form
        :feedbacks="mb.statics('Partner_to_pay').feedbacks"
        :vobject="$v"
        nested="partner_to_pay"
        ref="form"
        class="form-row"
      >
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>_id<span class='required_field'>*</span></label>
        <tc-input placeholder='Type the value' name='_id' v-model="partner_to_pay._id"></tc-input>
      </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>Name</label>
        <tc-input placeholder='Type the value' name='name' v-model="partner_to_pay.name"></tc-input>
      </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>Paypal</label>
        <tc-input placeholder='Type the value' name='paypal' v-model="partner_to_pay.paypal"></tc-input>
      </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>Total_orders</label>
        <tc-input placeholder='Type the value'   type_car='num'  name='total_orders' v-model="partner_to_pay.total_orders"></tc-input>
      </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>Total_pay</label>
        <tc-input placeholder='Type the value'   type_car='dec'  name='total_pay' v-model="partner_to_pay.total_pay"></tc-input>
      </tc-form-item>
      </tc-form>
    </div>
    <div class="card-footer p-0">
      <button :disabled="loading" class="btn btn-primary" @click="save_model()">{{button_text}}</button>
      <button v-if="!partner_to_pay.get_id()" :disabled="loading" class="btn btn-primary" @click="save_model(true)">Añadir y nuevo</button>
      <button :disabled="loading" class="btn btn-danger" @click="cancel()">Cancel</button>
    </div>
  </div>
</template>

<script>
import Partner_to_pay from "../../../../../entities/models/modules/managment/partner_to_pay.model";
import * as utils from "../../../../../entities/utils/utils";
import * as mb from "../../../../../entities/models"

export default {
  name: "partner_to_pay_form",
  inject: {
      close_modal: { default: ()=>{} },
      load_data: {default: () => {} }
  },
  props: {
    model: {
      type: Object,
      default: () => {}
    },
      modal: {
        type: Boolean,
        default: false
      },
    popoverPlacement: {
      type: String,
      default: "bottomLeft"
    }
  },
  validations: mb.statics('Partner_to_pay').validations,
  data() {
    return {
      loading: false,
      mb,      // This property is for load static or instance class
      partner_to_pay: mb.instance( 'Partner_to_pay'),
    };
  },
  computed: {
    partner_to_payFeedbacks() {
      return mb.statics('Partner_to_pay').feedbacks;
    },
    button_text() {
      return this.partner_to_pay.get_id() ? "Actualizar" : "Añadir";
    },
  },

  mounted: function() {
    this.partner_to_pay = mb.instance( 'Partner_to_pay',this.model);

  },
  components: {

             },
  methods: {
      cancel(){
        if (!this.model) {
          this.$emit('close_modal',false)
        } else {
        this.modal?this.close_modal(null,false):this.$router.push({name: 'partner_to_pay_list'})
       }
      },
    save_model(and_new=false) {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const accion=this.partner_to_pay.get_id() ? "actualizado" : "añadido";
        this.partner_to_pay
          .save()
          .then((response) => {
            if(utils.process_response(response,accion)) {
              if (!this.model && !and_new && this.modal) {

                  this.$emit('close_modal',true)
                  return;
               }
                else {
                   !and_new?this.modal?this.close_modal(null,true):this.$router.push({name: 'partner_to_pay_list'}):this.partner_to_pay=mb.instance('Partner_to_pay');               }
               this.load_data()
               this.$refs.form.vobject.$reset()

            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            utils.process_error(error);
          });
      }
    }
  }
};
</script>

<style scoped>
@import "partner_to_pay_form.css";
</style>

