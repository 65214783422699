/**Generate by ASGENS
 *@author Charlietyn
 *@date Thu Aug 26 11:43:49 GMT-04:00 2021
 *@time Thu Aug 26 11:43:49 GMT-04:00 2021
 */
import {
    required,
    integer,
    between,
    maxLength,
    minLength,
    decimal
} from 'vuelidate/lib/validators';

import BaseModel from '../../base.model';

import moment from 'moment';
import {DateTime} from "luxon";

const url = 'housecall';

export default class Housecalls extends BaseModel {

    _id
    altitude
    client
    confirmated
    created_At
    housecall_fee
    latitude
    longitude
    offers
    pet
    presentingComplain
    state
    status
    tecs
    vet
    date
    services
    prescription

    constructor(attributes = null) {
        super();
        if (attributes != null) {

            this._id = attributes._id || undefined
            this.altitude = attributes.altitude || null
            this.client = attributes.client || null
            this.confirmated = attributes.confirmated || null
            this.created_At = attributes.created_At || null
            this.housecall_fee = attributes.housecall_fee || null
            this.latitude = attributes.latitude || null
            this.longitude = attributes.longitude || null
            this.offers = attributes.offers || null
            this.pet = attributes.pet || null
            this.presentingComplain = attributes.presentingComplain || null
            this.state = attributes.state || null
            this.status = attributes.status || null
            this.tecs = attributes.tecs || null
            this.vet = attributes.vet || null
            this.date = attributes.date || null
            this.services = attributes.services || null
            this.prescription = attributes.prescription || null
        }
    }

    set_attributes(attributes = null) {
        if (attributes != null) {

            this._id = attributes._id
            this.altitude = attributes.altitude
            this.client = attributes.client
            this.confirmated = attributes.confirmated
            this.created_At = attributes.created_At
            this.housecall_fee = attributes.housecall_fee
            this.latitude = attributes.latitude
            this.longitude = attributes.longitude
            this.offers = attributes.offers
            this.pet = attributes.pet
            this.presentingComplain = attributes.presentingComplain
            this.state = attributes.state
            this.status = attributes.status
            this.tecs = attributes.tecs
            this.vet = attributes.vet
            this.date = attributes.date
            this.services = attributes.services
            this.prescription = attributes.prescription
        }
    }

    static validations = {
        housecalls: {
            _id: {
                required,
            },
            altitude: {
                integer,
            },
            client: {},
            confirmated: {},
            created_At: {},
            housecall_fee: {
                integer,
            },
            latitude: {
                decimal,
            },
            longitude: {
                decimal,
            },
            offers: {
                maxLength: maxLength(65535),
            },
            pet: {},
            presentingComplain: {},
            state: {},
            status: {},
            tecs: {
                maxLength: maxLength(65535),
            },
            vet: {},
        },
    }

    static feedbacks = {
        housecalls: {
            _id: {
                isUnique: 'This _id has been taken'

            },
        },
    }

    static columns = [
        {
            title: 'Id',
            dataIndex: '_id',
            align: 'center',
            key: '_id',
            width: "25%",
            sorter: (a, b) => (a._id > b._id) - (a._id < b._id)
        },
        {
            title: 'Altitude',
            dataIndex: 'altitude',
            align: 'center',
            key: 'altitude',

            sorter: (a, b) => a.altitude - b.altitude
        },
        {
            title: 'Client',
            dataIndex: 'client.name',
            align: 'center',
            width: "20%",
            key: 'client._id',

            sorter: (a, b) => a.client && b.client ? (a.client.name > b.client.name) - (a.client.name < b.client.name) : 0
        },
        {
            title: 'Created',
            align: 'center',
            key: 'created_At',
            width: "15%",
            customRender: (value) => {
                return DateTime.fromMillis(value.created_At).toFormat('dd-MM-yyyy h:mm')
            },
            use_filter: true,
            sorter: (a, b) => a.created_At - b.created_At
        },
        {
            title: 'Date',
            align: 'center',
            key: 'date',
            width: "15%",
            customRender: (value) => {
                return value.date ? DateTime.fromMillis(value.date).toFormat('dd-MM-yyyy h:mm') : "--"
            },
            use_filter: true,
            sorter: (a, b) => a.date - b.date
        },
        {
            title: 'Housecall_fee',
            dataIndex: 'housecall_fee',
            align: 'center',
            key: 'housecall_fee',

            sorter: (a, b) => a.housecall_fee - b.housecall_fee
        },
        {
            title: 'Latitude',
            dataIndex: 'latitude',
            align: 'center',
            key: 'latitude',

        },
        {
            title: 'Longitude',
            dataIndex: 'longitude',
            align: 'center',
            key: 'longitude',

        },
        {
            title: 'Offers',
            dataIndex: 'offers',
            align: 'center',
            key: 'offers',

            sorter: (a, b) => (a.offers > b.offers) - (a.offers < b.offers)
        },
        {
            title: 'Pet',
            dataIndex: 'pet',
            width: "7%",
            align: 'center',
            key: 'pet',

            sorter: (a, b) => (a.pet > b.pet) - (a.pet < b.pet)
        },
        {
            title: 'PresentingComplain',
            dataIndex: 'presentingComplain',
            align: 'center',
            key: 'presentingComplain',

            sorter: (a, b) => (a.presentingComplain > b.presentingComplain) - (a.presentingComplain < b.presentingComplain)
        },
        {
            title: 'State',
            dataIndex: 'state',
            align: 'center',
            key: 'state',

            sorter: (a, b) => (a.state > b.state) - (a.state < b.state)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: "10%",
            align: 'center',
            key: 'status',
            scopedSlots: {
                customRender: 'status'
            },
            sorter: (a, b) => (a.status > b.status) - (a.status < b.status)
        },
        {
            title: 'Tecs',
            dataIndex: 'tecs',
            align: 'center',
            key: 'tecs',

            sorter: (a, b) => (a.tecs > b.tecs) - (a.tecs < b.tecs)
        },
        {
            title: 'Vet',
            dataIndex: 'vet._id',
            align: 'center',
            key: 'vet._id',

            sorter: (a, b) => a.vet && b.vet ? (a.vet._id > b.vet._id) - (a.vet._id < b.vet._id) : 0
        },
        {
            title: 'Acciones',
            key: 'action_elements',
            scopedSlots: {
                customRender: 'action'
            }
        }
    ];

    static get url() {
        return url
    };

    get url() {
        return url
    };

    get_id() {
        return this._id;
    }

    class_name() {
        return 'Housecalls'
    }


}

