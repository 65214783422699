/**Generate by ASGENS
*@author Charlietyn 
*@date Thu Aug 26 11:43:49 GMT-04:00 2021  
*@time Thu Aug 26 11:43:49 GMT-04:00 2021  
*/
import {
    required,
    integer,
    between,
    maxLength,
    minLength,
    decimal
  } from 'vuelidate/lib/validators';

  import BaseModel from '../../base.model';

  import moment from 'moment';

  const url = 'clients';

    export default class Clients extends BaseModel {

       _id
       first_name
       last_name
       phone
       email
       address
       state
       country
       zipcode
       password
       token
       device_token
       device_type
       login_by
       social_unique_id
       created_at
       updated_at
       latitude
       longitude
       timezone
       device
       version
       city
       opened_at
       type
       exc_partners

    constructor(attributes = null) {
      super();
      if (attributes != null) {

        this._id = attributes._id|| undefined
        this.first_name = attributes.first_name|| null
        this.last_name = attributes.last_name|| null
        this.phone = attributes.phone|| null
        this.email = attributes.email|| null
        this.address = attributes.address|| null
        this.state = attributes.state|| null
        this.country = attributes.country|| null
        this.zipcode = attributes.zipcode|| null
        this.password = attributes.password|| null
        this.exc_partners = attributes.exc_partners|| []
        this.token = attributes.token|| null
        this.device_token = attributes.device_token|| null
        this.device_type = attributes.device_type|| null
        this.login_by = attributes.login_by|| null
        this.social_unique_id = attributes.social_unique_id|| null
        this.created_at = attributes.created_at?moment(attributes.created_at).format('YYYY-MM-DD'):null
        this.updated_at = attributes.updated_at?moment(attributes.updated_at).format('YYYY-MM-DD'):null
        this.latitude = attributes.latitude|| null
        this.longitude = attributes.longitude|| null
        this.timezone = attributes.timezone|| null
        this.device = attributes.device|| null
        this.version = attributes.version|| null
        this.city = attributes.city|| null
        this.opened_at = attributes.opened_at?moment(attributes.opened_at).format('YYYY-MM-DD'):null
        this.type = attributes.type|| null
      }
    }

    set_attributes(attributes = null) {
      if (attributes != null) {

        this._id = attributes._id
        this.first_name = attributes.first_name
        this.last_name = attributes.last_name
        this.phone = attributes.phone
        this.email = attributes.email
        this.address = attributes.address
        this.exc_partners = attributes.exc_partners
        this.state = attributes.state
        this.country = attributes.country
        this.zipcode = attributes.zipcode
        this.password = attributes.password
        this.token = attributes.token
        this.device_token = attributes.device_token
        this.device_type = attributes.device_type
        this.login_by = attributes.login_by
        this.social_unique_id = attributes.social_unique_id
        this.created_at = moment(attributes.created_at).format('YYYY-MM-DD')
        this.updated_at = moment(attributes.updated_at).format('YYYY-MM-DD')
        this.latitude = attributes.latitude
        this.longitude = attributes.longitude
        this.timezone = attributes.timezone
        this.device = attributes.device
        this.version = attributes.version
        this.city = attributes.city
        this.opened_at = moment(attributes.opened_at).format('YYYY-MM-DD')
        this.type = attributes.type
      }
    }

    static validations = {
      clients: {
        _id: {
          required,
        },
        first_name: {
        },
        last_name: {
        },
        phone: {
        },
        email: {
          required,
        },
        address: {
        },
        state: {
        },
        country: {
        },
        zipcode: {
        },
        password: {
        },
        token: {
        },
        device_token: {
        },
        device_type: {
        },
        login_by: {
        },
        social_unique_id: {
        },
        created_at: {
        },
        updated_at: {
        },
        latitude: {
          decimal,
        },
        longitude: {
          decimal,
        },
        timezone: {
        },
        device: {
        },
        version: {
        },
        city: {
        },
        opened_at: {
        },
        type: {
          integer,
        },
      },
    }

    static feedbacks = {
      clients: {
      _id: {
        isUnique: 'This _id has been taken' 

      },
      },
    }

  static columns = [
    {
      title: 'Id',
      dataIndex: '_id',
      align:'center',
      key: '_id',
      width:"19%",
      sorter: (a, b) =>  (a._id > b._id)-(a._id < b._id)
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      align:'center',
      key: 'first_name',
      width:"15%",
      sorter: (a, b) =>  (a.first_name > b.first_name)-(a.first_name < b.first_name)
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      align:'center',
      key: 'last_name',
      width:"15%",
      sorter: (a, b) =>  (a.last_name > b.last_name)-(a.last_name < b.last_name)
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      align:'center',
      key: 'phone',
      width:"14%",
      sorter: (a, b) =>  (a.phone > b.phone)-(a.phone < b.phone)
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align:'center',
      key: 'email',
      width:"20%",
      sorter: (a, b) =>  (a.email > b.email)-(a.email < b.email)
    },
    {
      title: 'Address',
      dataIndex: 'address',
      align:'center',
      key: 'address',
      width:"15%",
      sorter: (a, b) =>  (a.address > b.address)-(a.address < b.address)
    },
    {
      title: 'State',
      dataIndex: 'state',
      align:'center',
      key: 'state',

      sorter: (a, b) =>  (a.state > b.state)-(a.state < b.state)
    },
    {
      title: 'Country',
      dataIndex: 'country',
      align:'center',
      key: 'country',

      sorter: (a, b) =>  (a.country > b.country)-(a.country < b.country)
    },
    {
      title: 'Zipcode',
      dataIndex: 'zipcode',
      align:'center',
      key: 'zipcode',

      sorter: (a, b) =>  (a.zipcode > b.zipcode)-(a.zipcode < b.zipcode)
    },
    {
      title: 'Token',
      dataIndex: 'token',
      align:'center',
      key: 'token',

      sorter: (a, b) =>  (a.token > b.token)-(a.token < b.token)
    },
    {
      title: 'Device_token',
      dataIndex: 'device_token',
      align:'center',
      key: 'device_token',

      sorter: (a, b) =>  (a.device_token > b.device_token)-(a.device_token < b.device_token)
    },
    {
      title: 'Device_type',
      dataIndex: 'device_type',
      align:'center',
      key: 'device_type',

      sorter: (a, b) =>  (a.device_type > b.device_type)-(a.device_type < b.device_type)
    },
    {
      title: 'Login_by',
      dataIndex: 'login_by',
      align:'center',
      key: 'login_by',

      sorter: (a, b) =>  (a.login_by > b.login_by)-(a.login_by < b.login_by)
    },
    {
      title: 'Social_unique_id',
      dataIndex: 'social_unique_id',
      align:'center',
      key: 'social_unique_id',

      sorter: (a, b) =>  (a.social_unique_id > b.social_unique_id)-(a.social_unique_id < b.social_unique_id)
    },
    {
      title: 'Latitude',
      dataIndex: 'latitude',
      align:'center',
      key: 'latitude',

    },
    {
      title: 'Longitude',
      dataIndex: 'longitude',
      align:'center',
      key: 'longitude',

    },
    {
      title: 'Timezone',
      dataIndex: 'timezone',
      align:'center',
      key: 'timezone',

      sorter: (a, b) =>  (a.timezone > b.timezone)-(a.timezone < b.timezone)
    },
    {
      title: 'Device',
      dataIndex: 'device',
      align:'center',
      key: 'device',

      sorter: (a, b) =>  (a.device > b.device)-(a.device < b.device)
    },
    {
      title: 'Version',
      dataIndex: 'version',
      align:'center',
      key: 'version',

      sorter: (a, b) =>  (a.version > b.version)-(a.version < b.version)
    },
    {
      title: 'City',
      dataIndex: 'city',
      align:'center',
      key: 'city',

      sorter: (a, b) =>  (a.city > b.city)-(a.city < b.city)
    },
    {
      title: 'Opened_at',
      dataIndex: 'opened_at',
      align:'center',
      key: 'opened_at',

      sorter: (a, b) => a.opened_at - b.opened_at
    },
    {
      title: 'Type',
      dataIndex: 'type',
      align:'center',
      key: 'type',

      sorter: (a, b) => a.type - b.type
    },
    {
      title: 'Actions',
      key: 'action_elements',
      fixed: 'right',
      scopedSlots: {
        customRender: 'action'
      }
    }
  ];

    static get url() {
      return url
    };
  
    get url() {
      return url
    };
  
    get_id() {
      return this._id;
    }
    class_name() {
        return 'Clients'
      }
  

   }

