/**Generate by ASGENS
*@author Charlietyn 
*@date Thu Aug 26 11:43:49 GMT-04:00 2021  
*@time Thu Aug 26 11:43:49 GMT-04:00 2021  
*/
import axios from 'axios';
import Vue from 'vue'
/*Globally*/
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.get['Accepts'] = 'application/json'

axios.pending = null
const reqInt = axios.interceptors.request.use(
    config => {
        // if (Vue.prototype.$store.site.user) {
        //     config.headers.Authorization = 'Bearer ' + Vue.prototype.$store.site.jwt
        // }
        return config
    }
)
const respInt = axios.interceptors.response.use(
    res => {
        return res
    },
    error => {
        error = error.response ? error : {
            response: {
                data: {
                    message: 'Cors error,Check preflight request, there is not response from server'
                },
                statusText: 'Cors Errors , There no status text'
            }
        }
        throw error
    }
)
// axios.interceptors.request.eject(reqInt)
// axios.interceptors.response.eject(respInt)
export default axios
export const Axios = axios

