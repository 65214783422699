/**Generate by ASGENS
*@author Charlietyn 
*@date Thu Aug 26 11:43:49 GMT-04:00 2021  
*@time Thu Aug 26 11:43:49 GMT-04:00 2021  
*/
import {
    required,
    integer,
    between,
    maxLength,
    minLength,
    decimal
  } from 'vuelidate/lib/validators';

  import BaseModel from '../../base.model';

  import moment from 'moment';

  const url = 'managment/offers';

    export default class Offers extends BaseModel {

       _id
       vet_fee
       created_At
       status
       tecs
       timeUntil
       timeFrom
       hc_id
       vet_id

    constructor(attributes = null) {
      super();
      if (attributes != null) {

        this._id = attributes._id|| undefined
        this.vet_fee = attributes.vet_fee|| null
        this.created_At = attributes.created_At?moment(attributes.created_At).format('YYYY-MM-DD'):null
        this.status = attributes.status|| null
        this.tecs = attributes.tecs|| null
        this.timeUntil = attributes.timeUntil|| null
        this.timeFrom = attributes.timeFrom|| null
        this.hc_id = attributes.hc_id|| null
        this.vet_id = attributes.vet_id|| null
      }
    }

    set_attributes(attributes = null) {
      if (attributes != null) {

        this._id = attributes._id
        this.vet_fee = attributes.vet_fee
        this.created_At = moment(attributes.created_At).format('YYYY-MM-DD')
        this.status = attributes.status
        this.tecs = attributes.tecs
        this.timeUntil = attributes.timeUntil
        this.timeFrom = attributes.timeFrom
        this.hc_id = attributes.hc_id
        this.vet_id = attributes.vet_id
      }
    }

    static validations = {
      offers: {
        _id: {
          required,
        },
        vet_fee: {
          integer,
        },
        created_At: {
        },
        status: {
        },
        tecs: {
          maxLength: maxLength(65535),
        },
        timeUntil: {
          integer,
        },
        timeFrom: {
          integer,
        },
        hc_id: {
        },
        vet_id: {
        },
      },
    }

    static feedbacks = {
      offers: {
      _id: {
        isUnique: 'This _id has been taken' 

      },
      },
    }

  static columns = [
    {
      title: '_id',
      dataIndex: '_id',
      align:'center',
      key: '_id',

      sorter: (a, b) =>  (a._id > b._id)-(a._id < b._id)
    },
    {
      title: 'Vet_fee',
      dataIndex: 'vet_fee',
      align:'center',
      key: 'vet_fee',

      sorter: (a, b) => a.vet_fee - b.vet_fee
    },
    {
      title: 'Created_At',
      dataIndex: 'created_At',
      align:'center',
      key: 'created_At',

      sorter: (a, b) => a.created_At - b.created_At
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align:'center',
      key: 'status',

      sorter: (a, b) =>  (a.status > b.status)-(a.status < b.status)
    },
    {
      title: 'Tecs',
      dataIndex: 'tecs',
      align:'center',
      key: 'tecs',

      sorter: (a, b) =>  (a.tecs > b.tecs)-(a.tecs < b.tecs)
    },
    {
      title: 'TimeUntil',
      dataIndex: 'timeUntil',
      align:'center',
      key: 'timeUntil',

      sorter: (a, b) => a.timeUntil - b.timeUntil
    },
    {
      title: 'TimeFrom',
      dataIndex: 'timeFrom',
      align:'center',
      key: 'timeFrom',

      sorter: (a, b) => a.timeFrom - b.timeFrom
    },
    {
      title: 'Hc',
      dataIndex: 'hc._id',
      align:'center',
      key: 'hc._id',

      sorter: (a, b) =>  a.hc && b.hc?(a.hc._id > b.hc._id)-(a.hc._id < b.hc._id):0
    },
    {
      title: 'Vet',
      dataIndex: 'vet._id',
      align:'center',
      key: 'vet._id',

      sorter: (a, b) =>  a.vet && b.vet?(a.vet._id > b.vet._id)-(a.vet._id < b.vet._id):0
    },
    {
      title: 'Acciones',
      key: 'action_elements',
      fixed: 'right',
      scopedSlots: {
        customRender: 'action'
      }
    }
  ];

    static get url() {
      return url
    };
  
    get url() {
      return url
    };
  
    get_id() {
      return this._id;
    }
    class_name() {
        return 'Offers'
      }
  

   }

