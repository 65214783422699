<template>
  <div ref="form_container" class="card position-relative mb-0" v-on:keyup.enter="save_model()">
    <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
      <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-1">
          <!--begin::Page Heading-->
          <div class="d-flex align-items-baseline flex-wrap mr-5">
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold my-1 mr-5">Clients</h5>
            <!--end::Page Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
              <li class="breadcrumb-item">
                <router-link :to="{name:'index'}" class="text-muted">Home</router-link>
              </li>
              <li class="breadcrumb-item">
                <a href="javascript:void(0)" class="text-muted">Managment</a>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name:'clients_list'}" class="text-muted">Client List</router-link>
              </li>
              <li class="breadcrumb-item">
                <a href="javascript:void(0)" class="text-muted">Clients {{ clients.first_name }} {{
                    clients.last_name
                  }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-sm-4">
      <a-spin class="col-12 col-md-12 col-lg-5" :spinning="loading" tip="wait ...">
        <div class="">
          <div class="card profile-widget">
            <div class="profile-widget-header">
              <img alt="image" src="/static/assets/img/avatar/user.jpg" class="rounded-circle profile-widget-picture">
              <div class="profile-widget-items">
                <div class="profile-widget-item">
                  <div class="profile-widget-item-label">Orders</div>
                  <div class="profile-widget-item-value">{{ total_orders }}</div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-4 col-12">
                    <label>Name</label>
                    <p class="text-muted form-text">{{ clients.first_name }}</p>
                  </div>
                  <div class="form-group col-md-4 col-12">
                    <label>Last Name</label>
                    <p class="text-muted form-text">{{ clients.last_name }}</p>
                  </div>
                  <div class="form-group col-md-4 col-12">
                    <label>Email</label>
                    <p>
                      <a :href="'mailto:'+clients.email" class="text-muted form-text">{{ clients.email }}</a>
                    </p>

                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-4 col-12">
                    <label>Phone</label>
                    <p>
                      <a :href="'tel:'+ clients.phone " class="text-muted form-text">{{ clients.phone }}</a>
                    </p>
                  </div>
                  <div class="form-group col-md-4 col-12">
                    <label>State</label>
                    <p class="text-muted form-text">
                      {{ clients.state }}
                    </p>

                  </div>
                  <div class="form-group col-md-4 col-12">
                    <label>City</label>
                    <p class="text-muted form-text">
                      {{ clients.city }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-6 col-12">
                    <label>Device</label>
                    <p class="text-muted form-text">
                      {{ clients.device }}
                    </p>
                  </div>
                  <div class="form-group col-md-3 col-12">
                    <label>Type</label>
                    <p class="text-muted form-text">
                      {{ clients.device_type ? clients.device_type.toUpperCase() : "" }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-7 col-12">
                    <label>Timezone</label>
                    <p class="text-muted form-text">
                      {{ clients.timezone }}
                    </p>

                  </div>
                  <div class="form-group col-md-3 col-12">
                    <label>Zip Code</label>
                    <p class="text-muted form-text">
                      {{ clients.zipcode }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group mb-0 col-6">
                    <label class="">Address</label>
                    <div class="text-muted form-text">{{ clients.address }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
      <div class="col-12 col-md-12 col-lg-7">
        <orders_simple :client_id="client_id"/>
      </div>
    </div>
    <fieldset class="p-3">
      <a-spin class="col-12 col-md-12 col-lg-12" :spinning="loading_transfer" tip="updating ...">
      <legend>Exclusive partners</legend>
      <div class="col-md-12">
        <a-transfer
            :listStyle="{
                        width: '45%',
                        height: '300px',
             }"
            :dataSource="partner_list"
            :titles="['Partner List', 'Exclusive Partners']"
            showSearch
            :filterOption="filterOption"
            :targetKeys="this.clients.exc_partners"
            @change="handleChange"
            @search="handleSearch"
            :render="item=>item.title"
        >
        </a-transfer>
      </div>
      </a-spin>
    </fieldset>
    <div class="card-footer text-right">
      <router-link :to="{name:'clients_list'}" class="btn btn-primary">Go to Clients</router-link>
    </div>
  </div>
</template>

<script>
import Clients from "../../../../../entities/models/modules/managment/clients.model";
import * as utils from "../../../../../entities/utils/utils";
import * as mb from "../../../../../entities/models"

export default {
  name: "clients_form",
  inject: {
    close_modal: {
      default: () => {
      }
    },
    load_data: {
      default: () => {
      }
    }
  },
  props: {
    model: {
      type: Object,
      default: () => {
      }
    },
    modal: {
      type: Boolean,
      default: false
    },
    popoverPlacement: {
      type: String,
      default: "bottomLeft"
    }
  },
  validations: mb.statics('Clients').validations,
  data() {
    return {
      loading: false,
      loading_transfer: false,
      mb,      // This property is for load static or instance class
      clients: mb.instance('Clients'),
      orders: [],
      client_id: "",
      partner_list: [],
      selected_partner: []
    };
  },
  computed: {
    clientsFeedbacks() {
      return mb.statics('Clients').feedbacks;
    },
    total_orders() {
      return this.orders.length
    },
    button_text() {
      return this.clients.get_id() ? "Actualizar" : "Añadir";
    },
  },
  async beforeMount() {
    if (this.$route.params.id != null) {
      this.$store.site.loading = true
      this.client_id = this.$route.params.id;
      const response = await mb.statics('Clients').view(this.$route.params.id)
      const response_order = await mb.statics('Orders').custom('get', '../' + this.$route.params.id + '/client')
      const response_partners = await mb.statics('Partners').list({status_account: "Verified"})
      this.orders = response_order.data
      this.partner_list = response_partners.data.map(element => {
        return {
          key: element._id,
          title: element.first_name + ' ' + element.last_name,
          description: element.first_name + ' ' + element.last_name,
        }
      })
      this.clients = mb.instance('Clients', response.data)
      this.$store.site.loading = false
    }
  },
  mounted: function () {
    this.clients = mb.instance('Clients', this.model);
  },
  components: {
    orders_simple: () => import("../../orders/list/orders_simple")
  },
  methods: {
    filterOption(inputValue, option) {
      return option.description.indexOf(inputValue) > -1;
    },
    handleChange(targetKeys, direction, moveKeys) {
      this.clients.exc_partners=targetKeys;
      this.loading_transfer = true;
      this.clients
          .save()
          .then((response) => {
            if (utils.process_response(response, "updated")) {
            }
            this.loading_transfer = false;
          })
          .catch((error) => {
            this.loading_transfer = false;
            utils.process_error(error);
          });
    },
    handleSearch(dir, value) {
    },
    save_model() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const accion = this.clients.get_id() ? "actualizado" : "añadido";
        this.clients
            .save()
            .then((response) => {
              if (utils.process_response(response, accion)) {
                if (!this.model && !and_new && this.modal) {

                  this.$emit('close_modal', true)
                  return;
                } else {
                  !and_new ? this.modal ? this.close_modal(null, true) : this.$router.push({name: 'clients_list'}) : this.clients = mb.instance('Clients');
                }
                this.load_data()
                this.$refs.form.vobject.$reset()
              }
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              utils.process_error(error);
            });
      }
    }
  }
};
</script>

<style scoped>
@import "clients_form.css";

.profile-widget .profile-widget-items .profile-widget-item {
  padding: 0 0 !important;
}

.profile-widget-item-label, .profile-widget-item-value, .profile-widget-item {
  width: 5rem !important;
  margin-right: 0px !important;
}

.profile-widget-items {
  width: 50% !important;

}
</style>

