<template>
  <div>
    <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
      <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-1">
          <!--begin::Page Heading-->
          <div class="d-flex align-items-baseline flex-wrap mr-5">
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold my-1 mr-5">House calls</h5>
            <!--end::Page Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
              <li class="breadcrumb-item">
                <router-link :to="{name:'index'}" class="text-muted">Home</router-link>
              </li>
              <li class="breadcrumb-item">
                <a href="javascript:void(0)" class="text-muted">Managment</a>
              </li>
              <li class="breadcrumb-item">
                <a href="javascript:void(0)" class="text-muted">House calls</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div>
        <div class="row">
          <div class="col-md-6">
          </div>
          <div class="col-md-6" style="text-align: end;">
            <div class="form-group">
              <div class="input-group mb-3">
                <a-tooltip placement="topLeft" title="Find..">
                  <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filter"
                      placeholder="Find"
                      aria-label
                  />
                </a-tooltip>
                <div class="input-group-append">
                  <a-tooltip placement="topLeft" title="Clean Filters">
                    <a-button
                        style="text-align: end"
                        class="rounded-0"
                        type="primary"
                        @click="filter=''"
                    >
                      <i class="fa fa-eraser" style="margin-right: 10px"/>Clean
                    </a-button>
                  </a-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="card card-custom">
        <div class="card-header py-3">
          <div class="col-md-6">
            <h6 style="text-align: left"> House calls</h6>
          </div>
          <div class="col-md-6" style="text-align: end;float: right">
            <div class="row">
              <div class="col-md-8" style="text-align: end">
                <p class="card-label" v-if="false" style="text-align: end;margin-top:10px">
                  Selecteds Items {{ selectedRowKeys.length }}/{{ data.length }}
                </p>
              </div>
              <div class="col-md-3">
                <a-dropdown-button style="margin-left: 45%;">
                  Export
                  <a-menu slot="overlay">
                    <a-menu-item key="1" @click="exportToExcel">
                      <a-icon type="file-excel" theme="twoTone" twoToneColor="#52c41a"/>
                      EXCEL
                    </a-menu-item>
                    <a-menu-item key="3" @click="exportToCSV">
                      <a-icon type="file-text" theme="twoTone" twoToneColor="#2771c4"/>
                      CSV
                    </a-menu-item>
                  </a-menu>
                </a-dropdown-button>
              </div>
            </div>
          </div>
        </div>
        <div class="section-header">
          <h1>Filters</h1>
          <div class="row offset-lg-4">
            <label class="col-md-4 ml-3 float-right" style="align-self: flex-end">Filter by Date</label>
            <a-range-picker class="col-md-7 mr-2" @change="onChange"/>
          </div>
          <div class="col-md-3">
            <div class="dropdown d-inline mr-2">
              <label class="mr-2">Filter by Status</label>
              <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ search_filter }}
              </button>
              <div class="dropdown-menu" x-placement="bottom-start"
                   style="position: absolute; transform: translate3d(0px, 29px, 0px); top: 0px; left: 0px; will-change: transform;">
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter='All'">All</a>
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter='Created'">Created</a>
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter='Started'">Started</a>
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter='Finished'">Finished</a>
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter='Accepted'">Accepted</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div style="height: 500px; width: 100%">
            <l-map
                v-if="showMap"
                :zoom="zoom"
                :center="center"
                :options="mapOptions"
                style="height: 80%"
                @update:center="centerUpdate"
                @update:zoom="zoomUpdate"
            >
              <l-tile-layer
                  :url="url"
                  :attribution="attribution"
              />
              <l-marker v-for="(item,index) in data" :lat-lng="llmap(item)" @click="innerClick(item)" :key="index" :icon="icon">
                <l-tooltip :options="{ permanent: false, interactive: true }" >
                  <div @click="innerClick(item)">
                    Client:{{ item.client.name }} <br>
                    Pet:{{ item.pet }}<br>
                    Date:{{
                      item.date || item.date !== 'null' ? moment(item.date).format('DD-MM-YYYY') : "No Date"
                    }}<br>
                    Status:{{ item.status }}<br>
                  </div>
                </l-tooltip>
              </l-marker>
            </l-map>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import * as utils from "../../../../../entities/utils/utils";
import * as mb from "../../../../../entities/models";
import vantdpagination from "../../../../shared/table/pagination/antd_pagination";
import housecalls_form from "../form/housecalls_form";
import {icon, latLng} from "leaflet";
import {LMap, LMarker, LTileLayer, LTooltip} from "vue2-leaflet";
import moment from 'moment';

export default {
  name: "housecalls_map",
  provide: function () {
    return {
      close_modal: this.onCloseModal,
      load_data: this.load_data
    }
  },
  data() {
    return {
      data: [],
      search_filter: "All",
      self: null,
      housecalls_list: [],
      range_date: null,
      filter: null,
      columns: mb.statics('Housecalls').show_columns([
        '_id',
        'client._id',
        'created_At',
        'date',
        'pet',
        'state',
        'status',
        'action_elements'
      ], true),
      showModalCreateclient: false,
      clients_list: [],
      showModalCreatevet: false,
      partners_list: [],
      zoom: 2.1551850354865096,
      icon: icon({
        iconUrl: "../../static/assets/img/web/icon.png",
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      center: latLng(38.83936867766911, -77.00709101704236),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a href="http://osm.org/copyright">Kwikvet</a>',
      withTooltip: latLng(38.83936867766911, -77.00709101704236),
      currentZoom: 2.1551850354865096,
      currentCenter: latLng(38.83936867766911, -77.00709101704236),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.0
      },
      showMap: true,
      loading: false,
      text_select: "Select All",
      selectedRowKeys: [],
      pagination: vantdpagination,
      selected_model: mb.instance('Housecalls'),
      show_modal_form: false,
      mb,
      moment
    };
  },
  components: {
    LMap, LTileLayer, LMarker, LTooltip
  },
  watch: {
    search_filter: function (newValue, oldValue) {
      this.filter_all()
    },
    filter: function () {
      this.data = this.housecalls_list.data.filter(this.filter_data);
    },
    range_date: function (newValue, oldValue) {

    },
    selectedRowKeys: function () {
      if (this.selectedRowKeys.length === this.data.length) {
        this.text_select = "Unselect";
      } else {
        this.text_select = "Seleccionar todo";
      }
    }
  },
  computed: {
    rowSelection() {
      const {selectedRowKeys} = this;
      return {
        selectedRowKeys,
        hideDefaultSelections: true,
        selections: [
          {
            key: "all-data",
            text: this.text_select,
            onSelect: () => {
              if (this.selectedRowKeys.length == this.data.length) {
                this.selectedRowKeys = [];
              } else {
                this.selectedRowKeys = this.data.map(e => {
                  return e._id;
                });
              }
            }
          }
        ],
        onSelection: this.onSelection,
        onChange: this.onChange
      };
    }
  },
  methods: {
    llmap(record) {
      return (record.latitude !== "null" && record.longitude !== "null") ? latLng(record.latitude, record.longitude) : latLng(38.83936867766911, -77.00709101704236)
    },
    location() {
      return this.housecalls ? latLng(this.housecalls.latitude, this.housecalls.longitude) : latLng(0, 0)
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick(record) {
      this.$router.push({name: 'housecalls_form', params: {id: record._id}})
    },
    exportToExcel() {
      utils.exportToExcelVinstance(this)
    },
    onShow(record) {
      this.$router.push({name: 'housecalls_form', params: {id: record._id}})
    },
    exportToCSV() {
      utils.exportToCSV(this)
    },
    onCloseModal(e, reload_data = false) {
      this.selected_model = mb.instance('Housecalls');
      this.show_modal_form = false;
      reload_data ? this.load_data() : ''
    },
    showModalForm() {
      this.show_modal_form = !this.show_modal_form;
    },
    filter_data(object) {
      return utils.filter_object_column(object, this.filter, this.columns);
    },
    onChange: function (dates) {
      this.range_date = dates;
      this.filter_all()
    },
    filter_all() {
      this.data = this.housecalls_list.data.filter(this.filter_data);
      if (this.search_filter !== 'All') {
        this.data = this.housecalls_list.data.filter(element => {
          return element.status.toLowerCase() === this.search_filter.toLowerCase()
        });
      }
      if (this.range_date && this.range_date.length > 0) {
        this.data = this.data.filter((element) => {
          if (!element.date || element.date === 'null') {
            return false
          }
          if (moment(element.date).isBetween(this.range_date[0], this.range_date[1])) {
            return true
          }
          return false
        });
      }
    },
    filter_status() {
      this.filter_all();

    },
    showDeleteConfirm() {
    },
    async load_data() {
      try {
        this.loading = true;
        var params = {};
        params.relations = ['client', 'vet'];
        const resp = await mb.statics('Housecalls').list(params);
        this.housecalls_list = resp;
        this.data = this.housecalls_list.data.filter(this.filter_data);
        this.loading = false;
      } catch (error) {
        utils.process_error(error);
        this.loading = false;
      }
    },
    onEditing(model) {
      this.selected_model = mb.instance('Housecalls', model);
      this.showModalForm();
    }
  },

  mounted() {
    this.loading = true;
    this.data = [];
    this.load_data();
    this.self = this;
    this.currentZoom = 2.1551850354865096,
        this.pagination.$options.props = {
          load_data: this.load_data
        };
  }
};
</script>

<style>
@import "housecalls_list.css";
</style>

