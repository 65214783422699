<template>
  <div class="main-sidebar sidebar-style-3">
    <aside id="sidebar-wrapper">
      <div class="sidebar-brand">
        <img src="/static/assets/img/web/9.webp" alt="logo" style="width:2.5rem" class="shadow-light rounded-circle mr-3">
        <router-link :to="{name:'index'}">Kwikvet</router-link>
      </div>
      <div class="sidebar-brand sidebar-brand-sm">
        <img src="/static/assets/img/web/9.webp" alt="logo" style="width:2.5rem;margin-left: 20%" class="shadow-light rounded-circle mr-3">
      </div>
      <ul class="sidebar-menu">
        <li class="menu-header">Welcome</li>
        <li class="menu-header">Administration Dashboard</li>
        <!--        student_evaluation_list_report-->
        <li class="dropdown">
          <a href="#" class="nav-link has-dropdown" data-toggle="dropdown"><i class="fas fa-columns"></i> <span>Administration Module</span></a>
          <ul class="dropdown-menu" style="display: block;">
            <li>
              <router-link class="nav-link" :to="{name:'clients_list'}">
                <span class="menu-title">Clients</span>
              </router-link>
            </li>
            <li>
              <router-link class="nav-link" :to="{name:'partners_list'}">
                <span class="menu-title">Partners</span>
              </router-link>
            </li>
            <li>
              <router-link class="nav-link" :to="{name:'orders_list'}">
                <span class="menu-title">Orders</span>
              </router-link>
            </li>
            <li>
              <router-link class="nav-link" :to="{name:'partner_to_pay_list'}">
                <span class="menu-title">Partner to pay</span>
              </router-link>
            </li>
            <li>
              <router-link class="nav-link" :to="{name:'housecalls_list'}">
                <span class="menu-title">House Call</span>
              </router-link>
            </li>
            <li>
              <router-link class="nav-link" :to="{name:'housecalls_map'}">
                <span class="menu-title">House Call Map</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
      <!--            <div class="mt-4 mb-4 p-3 hide-sidebar-mini">
                      <a href="https://getcodiepie.com/docs" class="btn btn-primary btn-lg btn-block btn-icon-split"><i class="fas fa-rocket"></i> Documentation</a>
                  </div>-->
    </aside>
  </div>
</template>

<script>
export default {
  name: 'sidebar',
  data () {
    return {}
  },
  methods: {},
  computed: {},
  watch:{},
  beforeMount () {
  }
}
</script>

<style scoped>

</style>
