/**Generate by ASGENS
 *@author Charlietyn
 *@date Thu Aug 26 11:43:49 GMT-04:00 2021
 *@time Thu Aug 26 11:43:49 GMT-04:00 2021
 */
import * as utils from './../utils/utils'

const requireModule = require.context("./", true, /(model.js$)/);
const regexp = new RegExp(/(\.\/|\.model.js)/g)
const classes = utils.dynamic_import(regexp, requireModule)

export function instance(class_name, attributes) {
    let class_instance = new classes[class_name].default(attributes)
    return utils.set_proxy(class_instance)
}

export function statics(class_name) {
    return classes[class_name].default
}

export default classes
