<template>
  <div ref="form_container" class="card position-relative mb-0">
    <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
      <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-1">
          <!--begin::Page Heading-->
          <div class="d-flex align-items-baseline flex-wrap mr-5">
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold my-1 mr-5">Partners</h5>
            <!--end::Page Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
              <li class="breadcrumb-item">
                <router-link :to="{name:'index'}" class="text-muted">Home</router-link>
              </li>
              <li class="breadcrumb-item">
                <a href="javascript:void(0)" class="text-muted">Managment</a>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name:'partners_list'}" class="text-muted">Partner List</router-link>
              </li>
              <li class="breadcrumb-item">
                <a href="javascript:void(0)" class="text-muted">Partner {{ partners.first_name }} {{
                    partners.last_name
                  }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-sm-4">
      <a-spin class="col-12 col-md-12 col-lg-5" :spinning="loading" tip="wait ...">
        <div class="">
          <div class="card profile-widget">
            <div class="profile-widget-header">
              <img alt="image" src="/static/assets/img/avatar/user.jpg" class="rounded-circle profile-widget-picture">
              <div class="profile-widget-items">
                <div class="profile-widget-item">
                  <div class="profile-widget-item-label">Orders</div>
                  <div class="profile-widget-item-value">{{ total_orders }}</div>
                </div>
                <div class="profile-widget-item">
                  <div class="profile-widget-item-label">Pays</div>
                  <div class="profile-widget-item-value">{{ orders_payed.length }}</div>
                </div>
                <div class="profile-widget-item">
                  <div class="profile-widget-item-label">Payments</div>
                  <div class="profile-widget-item-value">
                    <p class="text-success mb-2 font-weight-bold">${{ money_payed }}</p>
                  </div>
                </div>
                <div class="profile-widget-item">
                  <div class="profile-widget-item-label">To Pay</div>
                  <div class="profile-widget-item-value">
                    <p class="text-danger mb-2 font-weight-bold">${{ money_topay }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-4 col-12">
                    <label>Name</label>
                    <p class="text-muted form-text">{{ partners.first_name }}</p>
                  </div>
                  <div class="form-group col-md-4 col-12">
                    <label>Last Name</label>
                    <p class="text-muted form-text">{{ partners.last_name }}</p>
                  </div>
                  <div class="form-group col-md-4 col-12">
                    <label>Email</label>
                    <p>
                      <a style="white-space: nowrap" :href="'mailto:'+partners.email" class="text-muted form-text">{{ partners.email }}</a>
                    </p>

                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-4 col-12">
                    <label>Phone</label>
                    <p>
                      <a :href="'tel:'+ partners.phone " class="text-muted form-text">{{ partners.phone }}</a>
                    </p>
                  </div>
                  <div class="form-group col-md-4 col-12">
                    <label>State</label>
                    <p class="text-muted form-text">
                      {{ partners.state }}
                    </p>

                  </div>
                  <div class="form-group col-md-4 col-12">
                    <label>City</label>
                    <p class="text-muted form-text">
                      {{ partners.city }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-md-4 col-12">
                    <label>Device</label>
                    <p class="text-muted form-text">
                      {{ partners.device }}
                    </p>

                  </div>
                  <div class="form-group col-md-4 col-12">
                    <label>OS</label>
                    <p class="text-muted form-text">
                      {{ partners.os }}
                    </p>
                  </div>
                  <div class="form-group col-md-4 col-12">
                    <label>Type</label>
                    <p class="text-muted form-text">
                      {{ partners.type }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-6 col-12">
                    <label>Timezone</label>
                    <p class="text-muted form-text">
                      {{ partners.timezone }}
                    </p>

                  </div>
                  <div class="form-group col-md-3 col-12">
                    <label>Zip Code</label>
                    <p class="text-muted form-text">
                      {{ partners.zipcode }}
                    </p>
                  </div>
                  <div class="form-group col-md-3 col-12">
                    <label>Telehelp</label>
                    <p class="text-muted form-text">
                      <a href="#" v-if="partners.th_available" class="btn btn-social-icon btn-success mr-1"><i
                          class="fas fa-check"></i></a>
                      <a href="#" v-else class="btn btn-social-icon btn-danger mr-1"><i class="fas fa-times"></i></a>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group mb-0 col-6">
                    <label class="">Address</label>
                    <div class="text-muted form-text">{{ partners.address }}
                    </div>
                  </div>
                  <div class="form-group mb-0 col-6">
                    <tc-form-item class="form-group mb-0 px-3">
                      <label>License</label>
                      <tc-input placeholder='License' v-model="partners.license"></tc-input>
                    </tc-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group mb-0 col-12 mt-5 offset-8">
                    <button @click="save_model()" class="btn btn-social-icon btn-success mr-1"><i
                        class="fas fa-edit"></i>Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
      <div class="col-12 col-md-12 col-lg-7">
        <orders_simple :partner_id="partner_id"/>
      </div>
    </div>

    <div class="card-footer text-right">
      <router-link :to="{name:'partners_list'}" class="btn btn-primary">Go to Partners</router-link>
    </div>
  </div>
</template>

<script>
import Partners from "../../../../../entities/models/modules/managment/partners.model";
import * as utils from "../../../../../entities/utils/utils";
import * as mb from "../../../../../entities/models"

export default {
  name: "partners_form",
  inject: {
    close_modal: {
      default: () => {
      }
    },
    load_data: {
      default: () => {
      }
    }
  },
  props: {
    model: {
      type: Object,
      default: () => {
      }
    },
    modal: {
      type: Boolean,
      default: false
    },
    popoverPlacement: {
      type: String,
      default: "bottomLeft"
    }
  },
  validations: mb.statics('Partners').validations,
  data() {
    return {
      loading: false,
      partner_id: "",
      mb,      // This property is for load static or instance class
      partners: mb.instance('Partners'),
      orders: []
    };
  },
  computed: {
    partnersFeedbacks() {
      return mb.statics('Partners').feedbacks;
    },
    total_orders() {
      return this.orders.length
    },
    orders_payed() {
      return this.orders.length>0?this.orders.filter(element => element.Payed === true):[]
    },
    orders_no_payed() {
      return this.orders.length>0?this.orders.filter(element => element.Payed === false):[]
    },
    money_topay() {
      let result = 0
      this.orders_no_payed.length > 0 ? this.orders_no_payed.forEach((element) => {
        result += element.vet_fee ? element.vet_fee : 0
      }) : 0
      return result
    },
    money_payed() {
      let result = 0;
      this.orders_payed.length > 0 ? this.orders_payed.forEach((element) => {
        result += element.vet_fee
      }) : 0
      return result
    },
    button_text() {
      return this.partners.get_id() ? "Actualizar" : "Añadir";
    },
  },

  mounted: function () {
    this.partners = mb.instance('Partners', this.model);

  },
  components: {
    orders_simple:()=>import("../../orders/list/orders_simple")
  },
  methods: {
    save_model(and_new = false) {
      this.loading = true;
      const accion = this.partners.get_id() ? "updated" : "added";
      this.partners
          .save()
          .then((response) => {
            if (utils.process_response(response, accion)) {

            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            utils.process_error(error);
          });
    }
  },
  async beforeMount() {
    if (this.$route.params.id != null) {
      this.$store.site.loading = true
      this.partner_id = this.$route.params.id;
      const response = await mb.statics('Partners').view(this.$route.params.id)
      const response_order = await mb.statics('Orders').custom('get', '../' + this.$route.params.id + '/partner')
      this.orders = response_order.data
      this.partners = mb.instance('Partners', response.data)
      this.$store.site.loading = false
    }
  }
};
</script>

<style scoped>
@import "partners_form.css";

.profile-widget .profile-widget-items .profile-widget-item {
  padding: 0 0 !important;
}

.profile-widget-item-label, .profile-widget-item-value, .profile-widget-item {
  width: 5rem !important;
  margin-right: 0px !important;
}

.profile-widget-items {
  width: 50% !important;

}
</style>

