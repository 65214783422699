<template>
  <div>
    <div class="container">
      <div>
        <div class="row">
          <div class="col-md-6" style="text-align: end;">
            <div class="form-group">
              <div class="input-group mb-3">
                <a-tooltip placement="topLeft" title="Find..">
                  <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filter"
                      placeholder="Find"
                      aria-label
                  />
                </a-tooltip>
                <div class="input-group-append">
                  <a-tooltip placement="topLeft" title="Clean Filters">
                    <a-button
                        style="text-align: end"
                        class="rounded-0"
                        type="primary"
                        @click="filter=''"
                    >
                      <i class="fa fa-eraser" style="margin-right: 10px"/>Clean
                    </a-button>
                  </a-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="col-md-3 float-right mr-4">
              <a-dropdown-button style="margin-left: 45%;">
                Export
                <a-menu slot="overlay">
                  <a-menu-item key="1" @click="exportToExcel">
                    <a-icon type="file-excel" theme="twoTone" twoToneColor="#52c41a"/>
                    EXCEL
                  </a-menu-item>
                  <a-menu-item key="2" >
                    <a-icon type="file-pdf" theme="twoTone" twoToneColor="#c41c33"/>
                    PDF
                  </a-menu-item>
                  <a-menu-item key="3" @click="exportToCSV">
                    <a-icon type="file-text" theme="twoTone" twoToneColor="#2771c4"/>
                    CSV
                  </a-menu-item>
                </a-menu>
              </a-dropdown-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <a-table
          :columns="columns"
          :rowKey="record => record._id"
          :dataSource="data"
          :loading="loading"
          :pagination="pagination.$data"
      >
        <a slot="action" slot-scope="record" href="javascript:;">
          <action_buttons :object="record" :visible_view="false" :v_instance="self"
                          :class_name="selected_model.class_name()"/>
        </a>
      </a-table>
    </div>
  </div>
</template>

<script>
import * as utils from "../../../../../entities/utils/utils";
import * as mb from "../../../../../entities/models";
import action_buttons from "../../../../shared/table/action_buttons/action_buttons";
import pagination_functions from "../../../../shared/table/pagination/pagination_options";
import vantdpagination from "../../../../shared/table/pagination/antd_pagination";
import partner_to_pay_form from "../form/partner_to_pay_form";

export default {
  name: "partner_to_pay_index",
  provide: function () {
    return {
      close_modal: this.onCloseModal,
      load_data: this.load_data
    }
  },
  data() {
    return {
      data: [],
      self: null,
      partner_to_pay_list: [],
      filter: null,
      columns: mb.statics('Partner_to_pay').show_columns([
        '_id',
        'name',
        'paypal',
        'total_orders',
        'total_pay'
      ],true),
      loading: false,
      text_select: "Select All",
      selectedRowKeys: [],
      pagination: vantdpagination,
      selected_model: mb.instance('Partner_to_pay'),
      show_modal_form: false,
      mb
    };
  },
  components: {
    action_buttons,
    partner_to_pay_form
  },
  watch: {
    filter: function () {
      this.data = this.partner_to_pay_list.data.filter(this.filter_data);
    },
    selectedRowKeys: function () {
      if (this.selectedRowKeys.length == this.data.length) {
        this.text_select = "Unselect";
      } else {
        this.text_select = "Seleccionar todo";
      }
    }
  },
  computed: {
    rowSelection() {
      const {selectedRowKeys} = this;
      return {
        selectedRowKeys,
        hideDefaultSelections: true,
        selections: [
          {
            key: "all-data",
            text: this.text_select,
            onSelect: () => {
              if (this.selectedRowKeys.length == this.data.length) {
                this.selectedRowKeys = [];
              } else {
                this.selectedRowKeys = this.data.map(e => {
                  return e._id;
                });
              }
            }
          }
        ],
        onSelection: this.onSelection,
        onChange: this.onChange
      };
    }
  },
  methods: {
    exportToExcel() {
      utils.exportToExcelVinstance(this)
    },
    exportToCSV() {
      utils.exportToCSV(this)
    },
    onCloseModal(e, reload_data = false) {
      this.selected_model = mb.instance('Partner_to_pay');
      this.show_modal_form = false;
      reload_data ? this.load_data() : ''
    },
    showModalForm() {
      this.show_modal_form = !this.show_modal_form;
    },
    filter_data(object) {
      return utils.filter_object_column(object, this.filter, this.columns);
    },
    onChange: function (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    showDeleteConfirm() {
      if (this.selectedRowKeys.length == 0) {
        utils.openNotificationWithIcon(
            "error",
            "Delete Selecteds Items",
            "You must select at least one item"
        );
        return;
      }
      let _this = this;
      this.$confirm({
        title: "Delete Selecteds Items?",
        icon: "delete",
        // icon:()=>{return ( <a-icon type="delete" style="color:red"/> )},
        okText: "Si",
        okType: "danger",
        class: "delete_confirm",
        cancelText: "No",
        async onOk() {
          try {
            const response = await mb.statics('Partner_to_pay').delete_by_ids(
                _this.selectedRowKeys
            );
            utils.process_response(response, "deleted");
            _this.selectedRowKeys = [];
            _this.load_data();
          } catch (error) {
            utils.process_error(error);
            _this.selectedRowKeys = [];
          }
        },
        onCancel() {
        }
      });
    },
    async load_data() {
      try {
        this.loading = true;
        var params = {};
        params.relations = [];

        const resp = await mb.statics('Partner_to_pay').list(params);
        this.partner_to_pay_list = resp;
        this.data = this.partner_to_pay_list.data.filter(this.filter_data);
        this.loading = false;
        this.pagination.total = this.data.length
      } catch (error) {
        utils.process_error(error);
        this.loading = false;
      }
    },


    onEditing(model) {
      this.selected_model = mb.instance('Partner_to_pay', model);
      this.showModalForm();
    }
  },

  mounted() {
    this.loading = true;
    this.data = [];
    this.load_data();
    this.self = this;
    this.pagination.$options.props = {
      load_data: this.load_data
    };
  }
};
</script>

<style>
@import "partner_to_pay_list.css";
</style>

