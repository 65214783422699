import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import router from './config/router'
import LoadScript from 'vue-plugin-load-script'
import {state} from './config/store/'
import './components/shared'
import './assets/scss/main.scss'
/*Leaflet*/
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
// /* Antd */
import 'ant-design-vue/dist/antd.css'
import Antd from 'ant-design-vue'


/*Jquery*/
window.$ = window.jQuery = require('jquery')

/* using components */
Vue.use(LoadScript)
Vue.use(Antd)
Vue.use(Vuelidate)
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.prototype.$store = state


new Vue({
    el: '#app',
    router,
    render: h => h(App)
})
