<template>
  <div class="mt-5">
    <Count_elements/>
    <div class="row row-deck">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4>Partners a Pagar</h4>
          </div>
          <div class="card-body">
            <Partner_to_pay_index/>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import Count_elements from "./count_elements";
import Partner_to_pay_index from "../managment/partner_to_pay/list/partner_to_pay_index";
export default {
  name: "index",
  components: {Partner_to_pay_index, Count_elements},
  data() {
    return {
    }
  },
  methods: {},
  computed: {},
  watch: {},
  async beforeMount() {
  }
}
</script>

<style scoped>

</style>

