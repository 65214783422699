/**Generate by ASGENS
*@author Charlietyn 
*@date Thu Aug 26 11:43:49 GMT-04:00 2021  
*@time Thu Aug 26 11:43:49 GMT-04:00 2021  
*/
import {
    required,
    integer,
    between,
    maxLength,
    minLength,
    decimal
  } from 'vuelidate/lib/validators';

  import BaseModel from '../../base.model';

  import moment from 'moment';

  const url = 'managment/payments';

    export default class Payments extends BaseModel {

       _id
       createdAt
       nonce
       total
       order_id
       paymentDetails

    constructor(attributes = null) {
      super();
      if (attributes != null) {

        this._id = attributes._id|| undefined
        this.createdAt = attributes.createdAt?moment(attributes.createdAt).format('YYYY-MM-DD'):null
        this.nonce = attributes.nonce|| null
        this.total = attributes.total|| null
        this.order_id = attributes.order_id|| null
        this.paymentDetails = attributes.paymentDetails|| null
      }
    }

    set_attributes(attributes = null) {
      if (attributes != null) {

        this._id = attributes._id
        this.createdAt = moment(attributes.createdAt).format('YYYY-MM-DD')
        this.nonce = attributes.nonce
        this.total = attributes.total
        this.order_id = attributes.order_id
        this.paymentDetails = attributes.paymentDetails
      }
    }

    static validations = {
      payments: {
        _id: {
          required,
        },
        createdAt: {
        },
        nonce: {
        },
        total: {
          integer,
        },
        order_id: {
        },
        paymentDetails: {
          maxLength: maxLength(65535),
        },
      },
    }

    static feedbacks = {
      payments: {
      _id: {
        isUnique: 'This _id has been taken' 

      },
      },
    }

  static columns = [
    {
      title: '_id',
      dataIndex: '_id',
      align:'center',
      key: '_id',

      sorter: (a, b) =>  (a._id > b._id)-(a._id < b._id)
    },
    {
      title: 'CreatedAt',
      dataIndex: 'createdAt',
      align:'center',
      key: 'createdAt',

      sorter: (a, b) => a.createdAt - b.createdAt
    },
    {
      title: 'Nonce',
      dataIndex: 'nonce',
      align:'center',
      key: 'nonce',

      sorter: (a, b) =>  (a.nonce > b.nonce)-(a.nonce < b.nonce)
    },
    {
      title: 'Total',
      dataIndex: 'total',
      align:'center',
      key: 'total',

      sorter: (a, b) => a.total - b.total
    },
    {
      title: 'Order',
      dataIndex: 'order._id',
      align:'center',
      key: 'order._id',

      sorter: (a, b) =>  a.order && b.order?(a.order._id > b.order._id)-(a.order._id < b.order._id):0
    },
    {
      title: 'PaymentDetails',
      dataIndex: 'paymentDetails',
      align:'center',
      key: 'paymentDetails',

      sorter: (a, b) =>  (a.paymentDetails > b.paymentDetails)-(a.paymentDetails < b.paymentDetails)
    },
    {
      title: 'Acciones',
      key: 'action_elements',
      fixed: 'right',
      scopedSlots: {
        customRender: 'action'
      }
    }
  ];

    static get url() {
      return url
    };
  
    get url() {
      return url
    };
  
    get_id() {
      return this._id;
    }
    class_name() {
        return 'Payments'
      }
  

   }

