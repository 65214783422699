<template>
    <div>
        <div id="app">
            <div class="main-wrapper main-wrapper-1">
                <div class="navbar-bg"></div>

                <!-- Start app top navbar -->
                <top_bar/>

                <!-- Start main left sidebar menu -->
                <side_bar/>

                <!-- Start app main Content -->
                <div class="main-content">
                    <section class="section">
                      <a-spin :spinning="$store.site.loading" tip="Cargando ...">
                       <router-view/>
                      </a-spin>
                    </section>
                </div>

                <!-- Start app Footer part -->
                <footer_ban/>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import footer_ban from "../elements/footer_ban";
    import side_bar from "../elements/side_bar";
    import top_bar from "../elements/top_bar";

    export default {
        name: 'home',
        props: {
            logout: Function
        },
        components: {
            footer_ban,
            side_bar,
            top_bar
        },
        beforeCreate() {
        },
        created() {
        },
        beforeDestroy() {
            const recaptchaScripts = document.querySelectorAll('script')
            for (let i = 0; i < recaptchaScripts.length; i += 1) {
                recaptchaScripts[i].parentElement.removeChild(recaptchaScripts[i])
            }
        },
        async beforeMount() {
            await Promise.all([
                Vue.loadScript('/static/assets/bundles/lib.vendor.bundle.js').then(()=>{
                  Vue.loadScript('/static/assets/js/scripts.js')
                  Vue.loadScript('/static/assets/js/custom.js')
                }),
            ])
        }
    }
</script>

<style>

</style>
