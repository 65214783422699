<template>
  <div>
    <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
      <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-1">
          <!--begin::Page Heading-->
          <div class="d-flex align-items-baseline flex-wrap mr-5">
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold my-1 mr-5">Partners</h5>
            <!--end::Page Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
              <li class="breadcrumb-item">
                <router-link :to="{name:'index'}" class="text-muted">Home</router-link>
              </li>
              <li class="breadcrumb-item">
                <a href="javascript:void(0)" class="text-muted">Managment</a>
              </li>
              <li class="breadcrumb-item">
                <a href="javascript:void(0)" class="text-muted">Partners</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div>
        <div class="row">
          <div class="col-md-6">

          </div>
          <div class="col-md-6" style="text-align: end;">
            <div class="form-group">
              <div class="input-group mb-3">
                <a-tooltip placement="topLeft" title="Find..">
                  <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filter"
                      placeholder="Find"
                      aria-label
                  />
                </a-tooltip>
                <div class="input-group-append">
                  <a-tooltip placement="topLeft" title="Clean Filters">
                    <a-button
                        style="text-align: end"
                        class="rounded-0"
                        type="primary"
                        @click="filter=''"
                    >
                      <i class="fa fa-eraser" style="margin-right: 10px"/>Clean
                    </a-button>
                  </a-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="card card-custom">
        <div class="card-header py-3">
          <div class="col-md-2">
            <h6 style="text-align: left">Partners</h6>
          </div>
          <div class="col-md-4 offset-6" style="text-align: end;float: right">
            <div class="row">
              <div class="col-md-6" style="text-align: end">
                <p class="card-label" style="text-align: end;margin-top:10px">
                  Selecteds Items {{ selectedRowKeys.length }}/{{ data.length }}
                </p>
              </div>
              <div class="col-md-3">
                <a-dropdown-button style="margin-left: 45%;">
                  Export
                  <a-menu slot="overlay">
                    <a-menu-item key="1" @click="exportToExcel">
                      <a-icon type="file-excel" theme="twoTone" twoToneColor="#52c41a"/>
                      EXCEL
                    </a-menu-item>
                    <a-menu-item key="2">
                      <a-icon type="file-pdf" theme="twoTone" twoToneColor="#c41c33"/>
                      PDF
                    </a-menu-item>
                    <a-menu-item key="3" @click="exportToCSV">
                      <a-icon type="file-text" theme="twoTone" twoToneColor="#2771c4"/>
                      CSV
                    </a-menu-item>
                  </a-menu>
                </a-dropdown-button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-header py-3">
          <div class="col-md-2">
            <h6 style="text-align: left">Filters</h6>
          </div>
          <div class="col-md-3">
            <div class="dropdown d-inline mr-2">
              <label class="mr-2">Filter status</label>
              <button class="btn btn-primary dropdown-toggle" type="button" id="combostatus"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ search_filter_status }}
              </button>
              <div class="dropdown-menu" x-placement="bottom-start"
                   style="position: absolute; transform: translate3d(0px, 29px, 0px); top: 0px; left: 0px; will-change: transform;">
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter_status='All'">All</a>
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter_status='Created'">Created</a>
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter_status='Verified'">Verified</a>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="dropdown d-inline mr-2">
              <label class="mr-2">Filter OS</label>
              <button class="btn btn-primary dropdown-toggle" type="button" id="combosos"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ search_filter_os }}
              </button>
              <div class="dropdown-menu" x-placement="bottom-start"
                   style="position: absolute; transform: translate3d(0px, 29px, 0px); top: 0px; left: 0px; will-change: transform;">
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter_os='All'">All</a>
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter_os='IOS'">IOS</a>
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter_os='ANDROID'">ANDROID</a>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="dropdown d-inline mr-2 row">
              <a-tooltip placement="topLeft" title="Version Filter">
                <tc-autocomplete
                    placeholder="Filter Version"
                    name="version"
                    class="col-md-10 mt-1"
                    ref="select_version"
                    :successFeed="false"
                    :defaultValue="'All'"
                    v-model="search_filter_version"
                    :data-source="version"
                />
              </a-tooltip>
            </div>
          </div>
        </div>
      </div>
      <a-modal
          @cancel="onCloseModal"
          :title="selected_model.get_id()?'Actualizar partners':'Añadir partners'"
          class="modal-form"
          width="55rem"
          :visible="show_modal_form"
          :destroyOnClose="true"
          on-ok="handleOk"
          :header="null"
          :footer="null"
          :maskClosable="false"

      >
        <partners_form :modal=true :model="selected_model"/>
      </a-modal>
      <a-modal
          @cancel="onCloseModalReset"
          :title="'Change Password --- Partner:' +selected_model.first_name+' '+selected_model.last_name"
          class="modal-form"
          width="55rem"
          :visible="show_modal_reset"
          :destroyOnClose="true"
          on-ok="handleOk"
          :header="null"
          :footer="null"
          :maskClosable="false"

      >
        <reset_password :modal=true :model="selected_model" :close_modal_reset="onCloseModalReset"/>
      </a-modal>

      <a-table
          :columns="columns"
          :rowKey="record => record._id"
          :dataSource="data"
          :loading="loading"
          :pagination="pagination.$data"

      >
        <div slot="status" slot-scope="record">
          <span v-if="record.status_account.toLowerCase()==='verified'"
                class="badge badge-success">{{ record.status_account }}</span>
          <span v-else class="badge badge-primary">{{ record.status_account }}</span>
        </div>
        <div slot="available" slot-scope="record">
          <span v-if="record.th_available===true" class="ant-checkbox ant-checkbox-checked"><span
              class="ant-checkbox-inner"></span></span>
          <span v-else class="ant-checkbox ant-checkbox"><span class="ant-checkbox-inner"></span></span>

        </div>
        <div slot="name" slot-scope="record">
          <p>{{ record.first_name }} {{ record.last_name }}</p>
        </div>
        <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
          <label class="font-weight-bold">Direccion:</label>
          <br/>
          {{ record.address }}
          <br/>
          <label class="font-weight-bold">Phone:</label>
          <br/>
          {{ record.phone }}

        </p>
        <a slot="action" slot-scope="record" href="javascript:;">
          <a-tooltip placement="topLeft" :title="'Partner: '+ record.first_name+' '+record.last_name">
            <button type="button" class="btn btn-primary btn-icon icon-left" @click="onShow(record)">
              <span class="badge" style="position: relative;top: -6px;margin-left: 0px;">
                <i class="fas fa-info-circle" style="margin: 0px"></i></span>
            </button>
            <button type="button" class="btn ml-2 btn-primary btn-icon icon-left" @click="onReset(record)">
              <span class="badge" style="position: relative;top: -6px;margin-left: 0px;">
                <i class="fas fa-lock" style="margin: 0px"></i></span>
            </button>
          </a-tooltip>
        </a>
      </a-table>
    </div>
  </div>
</template>

<script>
import * as utils from "../../../../../entities/utils/utils";
import * as mb from "../../../../../entities/models";
import vantdpagination from "../../../../shared/table/pagination/antd_pagination";
import partners_form from "../form/partners_form";
import reset_password from "../form/reset_password";

export default {
  name: "partners_list",
  provide: function () {
    return {
      close_modal: this.onCloseModal,
      close_modal_reset: this.onCloseModalReset,
      load_data: this.load_data
    }
  },
  data() {
    return {
      data: [],
      version: [],
      search_filter_status: "All",
      search_filter_os: "All",
      search_filter_version: "All",
      self: null,
      partners_list: [],
      filter: null,
      columns: mb.statics('Partners').show_columns([
        '_id',
        'first_name',
        'os',
        'version',
        'status_account',
        'email',
        'th_available',
        'action_elements'
      ], true),
      loading: false,
      text_select: "Select All",
      selectedRowKeys: [],
      pagination: vantdpagination,
      selected_model: mb.instance('Partners'),
      show_modal_form: false,
      show_modal_reset: false,
      mb
    };
  },
  components: {
    partners_form, reset_password
  },
  watch: {
    search_filter_status: function (newvalue, oldvalue) {
      this.load_data(this.params)
    },
    search_filter_os: function (newvalue, oldvalue) {
      console.log(this.params)
      this.load_data(this.params)
    },
    search_filter_version: function (newvalue, oldvalue) {
      console.log(this.params)
      this.load_data(this.params)
    },
    filter: function () {
      this.data = this.partners_list.data.filter(this.filter_data);
    },
    selectedRowKeys: function () {
      if (this.selectedRowKeys.length == this.data.length) {
        this.text_select = "Unselect";
      } else {
        this.text_select = "Seleccionar todo";
      }
    }
  },
  computed: {
    params() {
      const params = {};
      if (this.search_filter_os != "All") {
        params.os = this.search_filter_os.toLowerCase()
      }
      if (this.search_filter_status != "All") {
        params.status_account = this.search_filter_status
      }
      if (!this.search_filter_version)
        this.search_filter_version = "All"
      if (this.search_filter_version != "All") {
        params.version = this.search_filter_version
      }
      return params
    },
    rowSelection() {
      const {selectedRowKeys} = this;
      return {
        selectedRowKeys,
        hideDefaultSelections: true,
        selections: [
          {
            key: "all-data",
            text: this.text_select,
            onSelect: () => {
              if (this.selectedRowKeys.length == this.data.length) {
                this.selectedRowKeys = [];
              } else {
                this.selectedRowKeys = this.data.map(e => {
                  return e._id;
                });
              }
            }
          }
        ],
        onSelection: this.onSelection,
        onChange: this.onChange
      };
    }
  },
  methods: {
    onShow(record) {
      this.$router.push({name: 'partners_form', params: {id: record._id}})
    },
    exportToExcel() {
      utils.exportToExcelVinstance(this)
    },
    exportToCSV() {
      utils.exportToCSV(this)
    },
    onCloseModal(e, reload_data = false) {
      this.selected_model = mb.instance('Partners');
      this.show_modal_form = false;
      reload_data ? this.load_data() : ''
    },
    onCloseModalReset(e, reload_data = false) {
      this.show_modal_reset = false;
    },
    showModalForm() {
      this.show_modal_form = !this.show_modal_form;
    },
    showModalReset() {
      this.show_modal_reset = !this.show_modal_reset;
    },
    filter_data(object) {
      return utils.filter_object_column(object, this.filter, this.columns);
    },
    onChange: function (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    showDeleteConfirm() {
      if (this.selectedRowKeys.length == 0) {
        utils.openNotificationWithIcon(
            "error",
            "Delete Selecteds Items",
            "You must select at least one item"
        );
        return;
      }
      let _this = this;
      this.$confirm({
        title: "Delete Selecteds Items?",
        icon: "delete",
        // icon:()=>{return ( <a-icon type="delete" style="color:red"/> )},
        okText: "Si",
        okType: "danger",
        class: "delete_confirm",
        cancelText: "No",
        async onOk() {
          try {
            const response = await mb.statics('Partners').delete_by_ids(
                _this.selectedRowKeys
            );
            utils.process_response(response, "deleted");
            _this.selectedRowKeys = [];
            _this.load_data();
          } catch (error) {
            utils.process_error(error);
            _this.selectedRowKeys = [];
          }
        },
        onCancel() {
        }
      });
    },
    async load_data(params) {
      try {
        this.loading = true;
        const resp = await mb.statics('Partners').list({params: this.params});
        this.partners_list = resp;
        this.data = this.partners_list.data.filter(this.filter_data);
        this.loading = false;
        this.pagination.total = this.data.length
      } catch (error) {
        utils.process_error(error);
        this.loading = false;
      }
    },
    onReset(model) {
      this.selected_model = mb.instance('Partners', model);
      this.showModalReset();
    },
    onEditing(model) {
      this.selected_model = mb.instance('Partners', model);
      this.showModalForm();
    }
  },

  mounted() {
    this.loading = true
    this.data = [];
    this.load_data();
    this.self = this;
    mb.statics('Partners').custom('get', 'version').then(response => {
      this.version = response.data.sort().map((element, index) => {
        return {id: element, text: element}
      })
    })
    this.pagination.$options.props = {
      load_data: this.load_data
    };
  }
};
</script>

<style>
@import "partners_list.css";

@media (min-width: 768px) {
  .col-md-3 {
    max-width: 22% !important;
  }
}
</style>

