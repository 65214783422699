<template>
  <div>
    <div class="subheader py-2 py-lg-6 subheader-solid mb-5" id="kt_subheader">
      <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-1">
          <!--begin::Page Heading-->
          <div class="d-flex align-items-baseline flex-wrap mr-5">
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold my-1 mr-5">House calls</h5>
            <!--end::Page Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
              <li class="breadcrumb-item">
                <router-link :to="{name:'index'}" class="text-muted">Home</router-link>
              </li>
              <li class="breadcrumb-item">
                <a href="javascript:void(0)" class="text-muted">Managment</a>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name:'housecalls_list'}" class="text-muted">House calls</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div ref="form_container" class="card position-relative mb-0" v-on:keyup.enter="save_model()">
      <spinner v-if="loading"/>
      <div class="col-12 col-sm-12 col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4>House call <code>Info</code></h4> <span class="offset-8"
                                                        style="color:#c91a6b ">Created: {{ housecalls_created }}</span>
          </div>
          <div class="card-body">
            <ul class="nav nav-pills" id="myTab3" role="tablist">
              <li class="nav-item"><a class="nav-link active show" id="home-tab3" data-toggle="tab" href="#home3"
                                      role="tab"
                                      aria-controls="home" aria-selected="false">Information</a></li>
              <li class="nav-item"><a class="nav-link" id="profile-tab3" data-toggle="tab" href="#profile3" role="tab"
                                      aria-controls="profile" aria-selected="false">Elements</a></li>

            </ul>
            <div class="tab-content" id="myTabContent2">
              <div class="tab-pane fade active show" id="home3" role="tabpanel" aria-labelledby="home-tab3">
                <div class="row">
                  <ul class="list-group col-md-5">
                    <li class="list-group-item"><span style="color:#c91a6b ">Client:</span><span
                        class="ml-1"><strong>{{ client_name }}</strong></span></li>
                    <li class="list-group-item "><span style="color:#c91a6b ">Vet:</span><span
                        class="ml-1"><strong>{{ vet_name }}</strong></span></li>
                    <li class="list-group-item"><span style="color:#c91a6b ">Tecs:</span><span
                        class="ml-1"><strong>{{ tecs_name }}</strong></span></li>
                    <li class="list-group-item"><span style="color:#c91a6b ">Adress:</span><span
                        class="ml-1"><strong>{{ housecalls.address }}</strong></span></li>
                    <li class="list-group-item"><span style="color:#c91a6b ">Pet:</span><span
                        class="ml-1"><strong>{{ housecalls.pet }}</strong></span></li>
                  </ul>
                  <ul class="list-group col-md-5">
                    <li class="list-group-item"><span style="color:#c91a6b ">States:</span><span
                        class="ml-1"><strong>{{ housecalls.state }}</strong></span></li>
                    <li class="list-group-item"><span style="color:#c91a6b ">Status:</span><span
                        :class="'ml-1 badge '+badge_class"><strong>{{ housecalls.status }}</strong></span></li>
                    <li class="list-group-item"><span style="color:#c91a6b ">Fee:</span><span
                        class="ml-1"><strong>{{ housecalls.kwikvet_fee }}</strong></span></li>
                    <li class="list-group-item"><span style="color:#c91a6b ">Presenting Complain:</span><span
                        class="ml-1"><strong>{{ housecalls.presentingComplain }}</strong></span></li>
                    <li class="list-group-item"><span style="color:#c91a6b ">Date:</span><span
                        class="ml-1"><strong>{{ housecalls_date }}</strong></span></li>
                  </ul>
                </div>
                <div class="card-header">
                  <h4>Location <code>Info</code></h4>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div style="height: 500px; width: 100%">
                      <l-map
                          v-if="showMap"
                          :zoom="zoom"
                          :center="center"
                          :options="mapOptions"
                          style="height: 80%"
                          @update:center="centerUpdate"
                          @update:zoom="zoomUpdate"
                      >
                        <l-tile-layer
                            :url="url"
                            :attribution="attribution"
                        />
                        <l-marker :lat-lng="withTooltip" :icon="icon">
                          <l-tooltip :options="{ permanent: false, interactive: true }">
                            <div @click="innerClick">
                              Client:{{ client_name }} <br>
                              Pet:{{ housecalls.pet }}
                            </div>
                          </l-tooltip>
                        </l-marker>
                      </l-map>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="profile3" role="tabpanel" aria-labelledby="profile-tab3">
                <div class="row">
                  <div class="card col-md-6">
                    <div class="card-header">
                      <h4>Services</h4>
                    </div>
                    <div class="card-body">
                      <div class="list-group ">
                        <a href="#" :key="index" v-for="(element,index) in this.housecalls.services"
                           class="list-group-item list-group-item-action flex-column align-items-start">
                          <div class="d-flex w-100 justify-content-between">
                            <h6 class="mb-1 text-muted">Name:{{ element.name }}</h6>
                            <small class="text-muted">No.{{ index + 1 }}</small>
                          </div>
                          <h6 class="mb-1 text-muted">Price:{{ element.price }}</h6>
                          <h6 class="text-muted">Type:{{ element.type }}</h6>
                          <h6 class="text-muted">Subtype:{{ element.subtype }}</h6>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card col-md-6">
                    <div class="card-header">
                      <h4>Prescriptions</h4>
                    </div>
                    <div class="card-body">
                      <div class="list-group ">
                        <a href="#" :key="index" v-for="(element,index) in this.housecalls.prescription"
                           class="list-group-item list-group-item-action flex-column align-items-start">
                          <div class="d-flex w-100 justify-content-between">
                            <h6 class="mb-1 text-muted">Rx:{{ element.rx }}</h6>
                            <small class="text-muted">No.{{ index + 1 }}</small>
                          </div>
                          <h6 class="mb-1 text-muted">Inst:{{ element.inst }}</h6>
                          <h6 class="text-muted">Refill:{{ element.refill }}</h6>
                          <h6 class="text-muted">Qty:{{ element.qty }}</h6>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Housecalls from "../../../../../entities/models/modules/managment/housecalls.model";
import * as utils from "../../../../../entities/utils/utils";
import * as mb from "../../../../../entities/models"
import { latLng,icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";

import {DateTime} from "luxon";

export default {
  name: "housecalls_form",
  inject: {
    close_modal: {
      default: () => {
      }
    },
    load_data: {
      default: () => {
      }
    }
  },
  props: {
    model: {
      type: Object,
      default: () => {
      }
    },
    modal: {
      type: Boolean,
      default: false
    },
    popoverPlacement: {
      type: String,
      default: "bottomLeft"
    }
  },
  validations: mb.statics('Housecalls').validations,
  data() {
    return {
      loading: false,
      mb,      // This property is for load static or instance class
      housecalls: mb.instance('Housecalls'),
      showModalCreateclient: false,
      clients_list: [],
      showModalCreatevet: false,
      partners_list: [],
      zoom: 13,
      icon: icon({
        iconUrl: "../../static/assets/img/web/icon.png",
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      center: latLng(38.83936867766911, -77.00709101704236),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a href="http://osm.org/copyright">Kwikvet</a>',
      withTooltip: latLng(38.83936867766911, -77.00709101704236),
      currentZoom: 11.5,
      currentCenter: latLng(38.83936867766911, -77.00709101704236),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: true
    };
  },
  computed: {
    location(){
      return this.housecalls?latLng(this.housecalls.latitude, this.housecalls.longitude):latLng(0, 0)
    },
    badge_class() {
      if (this.housecalls.status) {
        if (this.housecalls.status.toLowerCase() === 'done')
          return "badge badge-success";
        if (this.housecalls.status.toLowerCase() === 'accepted')
          return "badge badge-success";
        if (this.housecalls.status.toLowerCase() === 'created')
          return "badge badge-info";
        if (this.housecalls.status.toLowerCase() === 'finished')
          return "badge badge-primary";
        if (this.housecalls.status.toLowerCase() === 'canceled')
          return "badge badge-danger";
        if (this.housecalls.status.toLowerCase() === 'started')
          return "badge badge-light";
        else
          return "badge badge-dark"
      } else {
        return ""
      }
    },
    housecalls_date() {
      return this.housecalls.date ? DateTime.fromMillis(this.housecalls.date).toFormat('dd-MM-yyyy h:mm a') : ""
    },
    housecalls_created() {
      return this.housecalls.created_At ? DateTime.fromMillis(this.housecalls.created_At).toFormat('dd-MM-yyyy h:mm a') : ""
    },
    housecallsFeedbacks() {
      return mb.statics('Housecalls').feedbacks;
    },
    tecs_name() {
      return this.housecalls.tecs ? this.housecalls.tecs.name : "No value"
    },
    vet_name() {
      return this.housecalls.vet ? this.housecalls.vet.name : "No value"
    },
    client_name() {
      return this.housecalls.client ? this.housecalls.client.name : "No value"
    },
    button_text() {
      return this.housecalls.get_id() ? "Actualizar" : "Añadir";
    },
  },

  mounted: async function () {
    if (this.$route.params.id != null) {
      this.$store.site.loading = true
      this.partner_id = this.$route.params.id;
      await mb.statics('Housecalls').view(this.$route.params.id).then(response => {
        this.housecalls = mb.instance('Housecalls', response.data.data)
        this.center=latLng(this.housecalls.latitude,this.housecalls.longitude,this.housecalls.altitude)
        this.withTooltip=latLng(this.housecalls.latitude,this.housecalls.longitude,this.housecalls.altitude)
        this.currentCenter=latLng(this.housecalls.latitude,this.housecalls.longitude,this.housecalls.altitude)
      }).catch(error => {
        utils.process_error(error)
        this.$router.push({'name': 'housecalls_list'})
      })
    } else {
      this.$router.push({'name': 'housecalls_list'})
    }
    this.$store.site.loading = false
  },
  components: {
    LMap, LTileLayer, LMarker, LTooltip
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
    openModalCreateclient() {
      this.showModalCreateclient = true;
    },
    clientAdded(refresh) {
      this.showModalCreateclient = false;
      refresh ? this.refreshclient() : '';
    },
    async refreshclient() {
      this.loading = true;
      await this.$refs.select_client.load();
      this.loading = false;
    },
    openModalCreatevet() {
      this.showModalCreatevet = true;
    },
    vetAdded(refresh) {
      this.showModalCreatevet = false;
      refresh ? this.refreshvet() : '';
    },
    async refreshvet() {
      this.loading = true;
      await this.$refs.select_vet.load();
      this.loading = false;
    },
    cancel() {
      if (!this.model) {
        this.$emit('close_modal', false)
      } else {
        this.modal ? this.close_modal(null, false) : this.$router.push({name: 'housecalls_list'})
      }
    },
    save_model(and_new = false) {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const accion = this.housecalls.get_id() ? "actualizado" : "añadido";
        this.housecalls
            .save()
            .then((response) => {
              if (utils.process_response(response, accion)) {
                if (!this.model && !and_new && this.modal) {

                  this.$emit('close_modal', true)
                  return;
                } else {
                  !and_new ? this.modal ? this.close_modal(null, true) : this.$router.push({name: 'housecalls_list'}) : this.housecalls = mb.instance('Housecalls');
                }
                this.load_data()
                this.$refs.form.vobject.$reset()

              }
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              utils.process_error(error);
            });
      }
    }
  }
};
</script>

<style scoped>
@import "housecalls_form.css";
</style>

