<template>
  <div ref="form_container" class="card position-relative mb-0" v-on:keyup.enter="save_model()">
    <spinner v-if="loading"/>
    <div class="card-body p-0">

      <tc-form
          :feedbacks="mb.statics('Partners').feedbacks"
          :vobject="$v"
          nested="partners"
          ref="form"
          class="form-row"
      >
        <tc-form-item class="form-group mb-0 col-md-6 px-3">
          <label>Password</label>
          <tc-input type="password" placeholder='Type the value' name='password' v-model="partners.password"></tc-input>
        </tc-form-item>
        <tc-form-item class="form-group mb-0 col-md-6 px-3">
          <label>Confirm Password</label>
          <tc-input placeholder='Type the value' name='confirm_password' type="password"
                    v-model="partners.confirm_password"></tc-input>
        </tc-form-item>
      </tc-form>
    </div>
    <div class="card-footer p-0">
      <a-button-group style="margin-bottom: 10px;margin-top: 10px">
        <a-tooltip placement="topLeft" title=" Change password">
          <a-button :disabled="loading" type="primary" @click="save_model()">{{ button_text }}</a-button>
        </a-tooltip>
        <a-tooltip placement="topLeft" title="Cancel Action">
          <a-button :disabled="loading" type="danger" @click="cancel()">Cancel</a-button>
        </a-tooltip>
      </a-button-group>
    </div>
  </div>
</template>

<script>
import * as utils from "../../../../../entities/utils/utils";
import * as mb from "../../../../../entities/models";

export default {
  name: "reset_password",
  inject: {
    close_modal_reset: {
      default: () => {
      }
    },
    load_data: {
      default: () => {
      }
    }
  },
  props: {
    model: {
      type: Object,
      default: () => {
      }
    },
    modal: {
      type: Boolean,
      default: false
    },
    popoverPlacement: {
      type: String,
      default: "bottomLeft"
    },
  },
  validations: mb.statics('Partners').validations,
  data() {
    return {
      loading: false,
      mb,      // This property is for load static or instance class
      partners: mb.instance('Partners'),
    };
  },
  computed: {
    partnersFeedbacks() {
      return mb.statics('Partners').feedbacks;
    },
    button_text() {
      return "Change password";
    },
  },

  mounted: function () {
    this.partners = mb.instance('Partners', this.model);
  },
  components: {},
  methods: {
    cancel() {
      this.close_modal_reset()
    },
    save_model(and_new = false) {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const accion = this.partners.get_id() ? "actualizado" : "añadido";
        let {_id, email, password} = this.partners
        this.partners = mb.instance("Partners", {_id, email, password})
        mb.statics("Partners")
            .custom("post", 'change_password', this.partners)
            .then((response) => {
              if (utils.process_response(response, accion)) {
                this.close_modal_reset()
                this.$refs.form.vobject.$reset()
              }
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              utils.process_error(error);
            });
      }
    }
  }
};
</script>

<style scoped>
@import "partners_form.css";
</style>

