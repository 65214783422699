<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="login-brand">
          <img src="/static/assets/img/web/logo.webp" alt="logo" width="150" class="shadow-light">
        </div>
        <div class="card card-primary">
          <div class="card-header">
            <h4>Administración de Kwikvet</h4>
          </div>
          <div class="card-body">
            <tc-form
                nested="user"
                :vobject="$v"
                ref="form"
                class="pt-3">
              <div class="form-group">
                <p v-if="error" class="p-t-60 fs-16" style="color: #d35147;margin-left: 20%">
                  {{message_error}}</p>
                <tc-form-item>
                  <!--                  <label class="control-label" style="color: #1c7430">Correo<span
                                        class="required_field">*</span></label>-->
                  <label for="inputUsername" class="control-label">
                    Correo
                    <span class="required_field">*</span>
                  </label>
                  <input type="email" class="form-control form-control-lg" v-model.trim="user.username" id="inputUsername" placeholder="NombreEmail">
                </tc-form-item>
              </div>
              <div class="form-group">
                <div class="d-block">
                  <label for="inputPassword" class="control-label">
                    Contraseña
                    <span class="required_field">*</span>
                  </label>
                  <div class="float-right">
                    <a class="text-small" style="color: #9d1352">
                      ¿Olvidaste tu contraseña?
                    </a>
                  </div>
                </div>
                <tc-form-item>
                  <input type="password" class="form-control form-control-lg" v-model="user.password" id="inputPassword" placeholder="Contraseña">
                </tc-form-item>
                <!--                <input id="password" type="password" class="form-control" name="password" tabindex="2" required>
                                <div class="invalid-feedback">
                                  Por favor introduzca su contraseña
                                </div>-->
              </div>
              <div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" name="remember" class="custom-control-input" tabindex="3" id="remember-me" @click="remember">
                  <label class="custom-control-label" for="remember-me">Recordarme</label>
                </div>
              </div>
              <div class="form-group">
                <button type="submit" @click="login_action" :loading="loading" class="btn btn-primary btn-lg btn-block" tabindex="4">
                  Iniciar sesión
                </button>
              </div>
            </tc-form>
          </div>
        </div>
        <div class="simple-footer">
          Copyright &copy; Kwikvet 2021-{{ year }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {required,} from 'vuelidate/lib/validators'
import Vue from "vue";
export default {
  name: 'login_form',
  inject: {
    login: {
      default: () => {
      }
    }
  },
  validations: {
    user: {
      username: {
        required
      },
      password: {
        required
      }
    }
  },
  data() {
    return {
      year:null,
      user: {
        username: '',
        password: '',
        remember_me: false,
      },
      error: false,
      loading: false,
      message_error: 'Error en usuario y/o contraseña'
    }
  },
  methods: {
    remember () {
      this.user.remember_me = !this.user.remember_me;
    },
    async login_action() {
      this.error = false
      this.loading = true
      if (this.$refs.form.validate()) {
        const user=this.$store.site.login(this.user);
        if(!user){
          this.error=true
          this.message_error='Error en su usuario y/o contraseña'
        }
        else{
          this.login();
        }
        // await this.$store.site.login(this.user).then((response) => {
        //   this.login()
        // }).catch((error) => {
        //   this.message_error= (!error.response || error.response.status !== 422) ? 'Unknow error, please contact with administrator' : 'Error en su usuario y/o contraseña'
        //   this.error = true
        // })
      }
      this.loading = false
    }
  },
  computed: {},
  watch:{},
  beforeMount () {
    this.year=new Date().getFullYear();
  }
}
</script>

<style scoped>
.btn-primary {
  background-color: #9d1352;
  color: #fff;
}
</style>
