/**Generate by ASGENS
 *@author Charlietyn
 *@date Thu Aug 26 11:43:49 GMT-04:00 2021
 *@time Thu Aug 26 11:43:49 GMT-04:00 2021
 */
import {integer, required, sameAs} from 'vuelidate/lib/validators';

import BaseModel from '../../base.model';

const url = 'partners';

export default class Partners extends BaseModel {

    _id
    first_name
    last_name
    phone
    email
    address
    state
    zipcode
    password
    device_token
    timezone
    device
    version
    city
    type
    os
    th_available
    license
    status_account
    confirm_password

    constructor(attributes = null) {
        super();
        if (attributes != null) {

            this._id = attributes._id || undefined
            this.first_name = attributes.first_name || null
            this.last_name = attributes.last_name || null
            this.phone = attributes.phone || null
            this.email = attributes.email || null
            this.license = attributes.license || null
            this.address = attributes.address || null
            this.state = attributes.state || null
            this.zipcode = attributes.zipcode || null
            this.password = attributes.password || null
            this.device_token = attributes.device_token || null
            this.timezone = attributes.timezone || null
            this.device = attributes.device || null
            this.version = attributes.version || null
            this.city = attributes.city || null
            this.type = attributes.type || null
            this.os = attributes.os || null
            this.th_available = attributes.th_available == '1' ? true : false
            this.status_account = attributes.status_account
        }
    }

    set_attributes(attributes = null) {
        if (attributes != null) {

            this._id = attributes._id
            this.first_name = attributes.first_name
            this.last_name = attributes.last_name
            this.phone = attributes.phone
            this.email = attributes.email
            this.license = attributes.license
            this.address = attributes.address
            this.state = attributes.state
            this.zipcode = attributes.zipcode
            this.password = attributes.password
            this.device_token = attributes.device_token
            this.timezone = attributes.timezone
            this.device = attributes.device
            this.version = attributes.version
            this.city = attributes.city
            this.type = attributes.type
            this.os = attributes.os
            this.th_available = attributes.th_available
            this.status_account = attributes.status_account
        }
    }

    static validations = {
        partners: {
            _id: {
                required,
            },
            first_name: {},
            license: {},
            last_name: {},
            phone: {},
            email: {
                required,
            },
            address: {},
            state: {},
            zipcode: {},
            password: {
                required
            },
            confirm_password: {
                required,
                sameAsPassword: sameAs('password')
            },
            device_token: {},
            timezone: {},
            device: {},
            version: {},
            city: {},
            type: {
                integer,
            },
            os: {},
            th_available: {},
        },
    }

    static feedbacks = {
        partners: {
            _id: {
                isUnique: 'This _id has been taken'
            },
            confirm_password: {
                sameAsPassword: 'This value must be same as the password field '
            },
        },
    }

    static columns = [
        {
            title: 'Id',
            dataIndex: '_id',
            align: 'center',
            key: '_id',
            width: "25%",
            sorter: (a, b) => (a._id > b._id) - (a._id < b._id)
        },
        {
            title: 'First Name',
            scopedSlots: {
                customRender: 'name'
            },
            align: 'center',
            key: 'first_name',
            width: "20%",
            sorter: (a, b) => (a.first_name > b.first_name) - (a.first_name < b.first_name)
        },
        {
            title: 'Status',
            scopedSlots: {
                customRender: 'status'
            },
            align: 'center',
            use_filter: true,
            key: 'status_account',
            width: "10%",
            sorter: (a, b) => (a.status_account > b.status_account) - (a.status_account < b.status_account)
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            align: 'center',
            key: 'last_name',
            width: "15%",
            sorter: (a, b) => (a.last_name > b.last_name) - (a.last_name < b.last_name)
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            align: 'center',
            key: 'phone',
            width: "13%",
            sorter: (a, b) => (a.phone > b.phone) - (a.phone < b.phone)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            align: 'center',
            key: 'email',
            width: "23%",
            sorter: (a, b) => (a.email > b.email) - (a.email < b.email)
        },
        {
            title: 'Address',
            dataIndex: 'address',
            align: 'center',
            key: 'address',
            width: "15%",
            sorter: (a, b) => (a.address > b.address) - (a.address < b.address)
        },
        {
            title: 'State',
            dataIndex: 'state',
            align: 'center',
            key: 'state',

            sorter: (a, b) => (a.state > b.state) - (a.state < b.state)
        },
        {
            title: 'Zipcode',
            dataIndex: 'zipcode',
            align: 'center',
            key: 'zipcode',

            sorter: (a, b) => (a.zipcode > b.zipcode) - (a.zipcode < b.zipcode)
        },
        {
            title: 'Password',
            dataIndex: 'password',
            align: 'center',
            key: 'password',

            sorter: (a, b) => (a.password > b.password) - (a.password < b.password)
        },
        {
            title: 'Device_token',
            dataIndex: 'device_token',
            align: 'center',
            key: 'device_token',

            sorter: (a, b) => (a.device_token > b.device_token) - (a.device_token < b.device_token)
        },
        {
            title: 'Timezone',
            dataIndex: 'timezone',
            align: 'center',
            key: 'timezone',

            sorter: (a, b) => (a.timezone > b.timezone) - (a.timezone < b.timezone)
        },
        {
            title: 'Device',
            dataIndex: 'device',
            align: 'center',
            key: 'device',

            sorter: (a, b) => (a.device > b.device) - (a.device < b.device)
        },
        {
            title: 'Version',
            dataIndex: 'version',
            align: 'center',
            key: 'version',

            sorter: (a, b) => (a.version > b.version) - (a.version < b.version)
        },
        {
            title: 'City',
            dataIndex: 'city',
            align: 'center',
            key: 'city',

            sorter: (a, b) => (a.city > b.city) - (a.city < b.city)
        },
        {
            title: 'Type',
            dataIndex: 'type',
            align: 'center',
            key: 'type',

            sorter: (a, b) => a.type - b.type
        },
        {
            title: 'Os',
            dataIndex: 'os',
            align: 'center',
            key: 'os',
            customRender: (value) => {
                return value ? value.toUpperCase() : 'No Value'
            },
            sorter: (a, b) => (a.os > b.os) - (a.os < b.os)
        },
        {
            title: 'Available',
            align: 'center',
            key: 'th_available',
            scopedSlots: {
                customRender: 'available'
            },
            sorter: (a, b) => (a.th_available > b.th_available) - (a.th_available < b.th_available)

        },
        {
            title: 'Actions',
            key: 'action_elements',
            fixed: 'right',
            scopedSlots: {
                customRender: 'action'
            }
        }
    ];

    static get url() {
        return url
    };

    get url() {
        return url
    };

    get_id() {
        return this._id;
    }

    class_name() {
        return 'Partners'
    }


}

export const PartnersProxy = new Partners();
