import firebase from 'firebase/compat/app';
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/storage";
import * as utils from './../utils/utils'

const chatarray = [
    {
        "-LhTiHGIfDQV00EH6KPb": {
            "date": "02:18 - 06/16/2019",
            "id": "211",
            "message": "Hi. This is Dr. Levisman. How can I help you? ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTiHGxo-HaNlhLYsK0": {
            "date": "22:18 - 06/15/2019",
            "id": "647u",
            "message": "Steffany Padilla has joined the video channel",
            "user": "Steffany Padilla"
        },
        "-LhTiOCEGfnAR_BsDZ7B": {
            "date": "22:19 - 06/15/2019",
            "id": "647u",
            "message": "hello , I noticed my dogs eyes are quite irritated ",
            "user": "Steffany Padilla"
        },
        "-LhTiU-Rcof2Q5908IJ3": {
            "date": "02:19 - 06/16/2019",
            "id": "211",
            "message": "When did you notice that? ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTibNpnA0rmhb7bsH_": {
            "date": "02:20 - 06/16/2019",
            "id": "211",
            "message": "For the first time",
            "user": "Dr. Damian Levisman"
        },
        "-LhTij7Wl0cunWdF5UQh": {
            "date": "22:20 - 06/15/2019",
            "id": "647u",
            "message": "today. his eyes are very red and droopy ",
            "user": "Steffany Padilla"
        },
        "-LhTip0uqudaSIvjcpND": {
            "date": "22:21 - 06/15/2019",
            "id": "647u",
            "message": "almost looks like he’s rolling his eyes back ",
            "user": "Steffany Padilla"
        },
        "-LhTivn3eNaRGNccX5Av": {
            "date": "02:21 - 06/16/2019",
            "id": "211",
            "message": "Any chance you could send me a picture? ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTj76y_Fc8dt6yvWKm": {
            "date": "22:22 - 06/15/2019",
            "id": "647u",
            "message": "",
            "user": "Steffany Padilla"
        },
        "-LhTj8tJeVGAM149sdc8": {
            "date": "22:22 - 06/15/2019",
            "id": "647u",
            "message": " ",
            "user": "Steffany Padilla"
        },
        "-LhTj9Cb4nqF3FZ_RXIt": {
            "date": "22:22 - 06/15/2019",
            "id": "647u",
            "message": "",
            "user": "Steffany Padilla"
        },
        "-LhTjArErIllwgjFDBnX": {
            "date": "22:22 - 06/15/2019",
            "id": "647u",
            "message": "Steffany Padilla has joined the video channel",
            "user": "Steffany Padilla"
        },
        "-LhTjC1SgGR5Y_DZ772g": {
            "id": "211",
            "message": "Dr. Damian Levisman has joined the video channel",
            "user": "Dr. Damian Levisman"
        },
        "-LhTjCgbAAZcIPbUFU5_": {
            "date": "22:22 - 06/15/2019",
            "id": "647u",
            "message": "",
            "user": "Steffany Padilla"
        },
        "-LhTjDldfPTpKCzehu-D": {
            "date": "22:22 - 06/15/2019",
            "id": "647u",
            "message": "Steffany Padilla has joined the video channel",
            "user": "Steffany Padilla"
        },
        "-LhTjMjV0V13ifnCQhvJ": {
            "date": "22:23 - 06/15/2019",
            "id": "647u",
            "message": "I’m not sure if you can see ",
            "user": "Steffany Padilla"
        },
        "-LhTjPeguhFIdg8_1uh8": {
            "date": "02:23 - 06/16/2019",
            "id": "211",
            "message": "OK, let me ask you a few more things",
            "user": "Dr. Damian Levisman"
        },
        "-LhTjQCLcwTDBM4zU5EL": {
            "date": "22:23 - 06/15/2019",
            "id": "647u",
            "message": "I don’t know how to send pictures on here ",
            "user": "Steffany Padilla"
        },
        "-LhTjTnyadpsPfONqt1V": {
            "date": "02:23 - 06/16/2019",
            "id": "211",
            "message": "That's fine, don't worry",
            "user": "Dr. Damian Levisman"
        },
        "-LhTjaC7iw0eMskkccsT": {
            "date": "22:24 - 06/15/2019",
            "id": "647u",
            "message": "",
            "user": "Steffany Padilla"
        },
        "-LhTjap2OlrPqrQvcYnV": {
            "date": "02:24 - 06/16/2019",
            "id": "211",
            "message": "Is he squinting the eyes a lot? Or rubbing them constantly? ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTjdsGbtSwEYsTvDYb": {
            "date": "22:24 - 06/15/2019",
            "id": "647u",
            "message": "yes ",
            "user": "Steffany Padilla"
        },
        "-LhTjoe_dSeGqzRZfKSb": {
            "date": "22:25 - 06/15/2019",
            "id": "647u",
            "message": "",
            "user": "Steffany Padilla"
        },
        "-LhTjtDQdaEwtyARhBeS": {
            "date": "02:25 - 06/16/2019",
            "id": "211",
            "message": "Did everything started by chance after a rough playing with another pet in the house or outside , a walk through bushes or similar? ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTk8TIag0cDVG9yhGv": {
            "date": "22:26 - 06/15/2019",
            "id": "647u",
            "message": "is there an option to send pictures through here ",
            "user": "Steffany Padilla"
        },
        "-LhTkFCd34HwfXb02S9N": {
            "date": "02:27 - 06/16/2019",
            "id": "211",
            "message": "On the video section, there is a button to take pictures. ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTkIg0ZSJ0irUWzE8z": {
            "date": "22:27 - 06/15/2019",
            "id": "647u",
            "message": "Steffany Padilla has joined the video channel",
            "user": "Steffany Padilla"
        },
        "-LhTkPyaluDHPLcet2Xg": {
            "date": "22:28 - 06/15/2019",
            "id": "647u",
            "message": "",
            "user": "Steffany Padilla"
        },
        "-LhTkWb8PeC-nZ07ohSd": {
            "date": "22:28 - 06/15/2019",
            "id": "647u",
            "message": "Steffany Padilla has joined the video channel",
            "user": "Steffany Padilla"
        },
        "-LhTkYTcPud-hCep_y_i": {
            "date": "02:28 - 06/16/2019",
            "id": "211",
            "message": "Somehow I can't see them, but that's OK, I was able to see something through the video. ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTk_ey6SzS9PYcV3D7": {
            "date": "02:28 - 06/16/2019",
            "id": "211",
            "message": "Let me explain you a little bit. ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTkeeHtEJablsN3AZO": {
            "date": "22:29 - 06/15/2019",
            "id": "647u",
            "message": "the inner corner of his eye is very red ",
            "user": "Steffany Padilla"
        },
        "-LhTklP1eboIsncPhTzH": {
            "date": "02:29 - 06/16/2019",
            "id": "211",
            "message": "As you know, through here I can't diagnose or prescribe unless somebody sees the pet. But I can guide you a little bit",
            "user": "Dr. Damian Levisman"
        },
        "-LhTkqaLJPDwPjd3M8GE": {
            "date": "22:29 - 06/15/2019",
            "id": "647u",
            "message": "I understand ",
            "user": "Steffany Padilla"
        },
        "-LhTkv9Cq8wDKcam_G5G": {
            "date": "02:30 - 06/16/2019",
            "id": "211",
            "message": "Is he still playful, eating ans drinking OK? ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTlCugDGTqIl-eoyfR": {
            "date": "22:31 - 06/15/2019",
            "id": "647u",
            "message": "he seems tired but he’s eating fine ",
            "user": "Steffany Padilla"
        },
        "-LhTlcTzQrGeU_5wAa3E": {
            "date": "02:33 - 06/16/2019",
            "id": "211",
            "message": "The main danger in these types of conditions are the eye ulcers (scratch). It's impossible to detect an ulcer unless you a specific test is performed on the eye, which obviously must be done by the Vet. ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTlkrnP7k9nmgD39Vv": {
            "date": "22:33 - 06/15/2019",
            "id": "647u",
            "message": " what could have caused this ",
            "user": "Steffany Padilla"
        },
        "-LhTlyn2qZWlHTvoX1wS": {
            "date": "02:34 - 06/16/2019",
            "id": "211",
            "message": "I'm not saying that this is the case. I'm warning you on worst case scenario. Ulcers could appear after scratching, trauma, foreign bodies, etc. Let me continue",
            "user": "Dr. Damian Levisman"
        },
        "-LhTmL4nwDRVtt20kFoG": {
            "date": "02:36 - 06/16/2019",
            "id": "211",
            "message": "What your pet is having could be also a conjunctivitis or an allergic episode. But the fact that he's squinting a lot and seems very uncomfortable, makes the chances of an ulcer at least possible, OK? ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTmYRaTCF1lISQ71-c": {
            "date": "22:37 - 06/15/2019",
            "id": "647u",
            "message": " I understand . he didn’t have this morning. ",
            "user": "Steffany Padilla"
        },
        "-LhTmbtYEjBIosiI37ga": {
            "date": "22:37 - 06/15/2019",
            "id": "647u",
            "message": " if his eyes continued like this . I’ll have to visit the vet tomorrow ",
            "user": "Steffany Padilla"
        },
        "-LhTmd0uBHM4RKC8A25t": {
            "date": "22:37 - 06/15/2019",
            "id": "647u",
            "message": " thank you ",
            "user": "Steffany Padilla"
        },
        "-LhTmfmI78K_3Y_X50bx": {
            "date": "02:37 - 06/16/2019",
            "id": "211",
            "message": "So, in this situations, my recommendation is to see a vet, so the Dr can perform a fluorescein test (it's a dying that is applied to the eye to visualize any potential scratch) ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTmoklGTTGsb4SAcUd": {
            "date": "02:38 - 06/16/2019",
            "id": "211",
            "message": "Absolutely. ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTmu14ULmnPh0U0Vvl": {
            "date": "22:38 - 06/15/2019",
            "id": "647u",
            "message": " thank you. I’ll be sure to do that it’s this continuous by tomorrow morning ",
            "user": "Steffany Padilla"
        },
        "-LhTmvy0b_5A1zji2ak3": {
            "date": "22:38 - 06/15/2019",
            "id": "647u",
            "message": " appreciate your help this late ",
            "user": "Steffany Padilla"
        },
        "-LhTnDzLqIpWHGNrVp5J": {
            "date": "02:40 - 06/16/2019",
            "id": "211",
            "message": "If by chance you have an ecollar (cone) at home, put it on, to avoid any rubbing of the eye and a potential new scratch",
            "user": "Dr. Damian Levisman"
        },
        "-LhTnGyrOYo4xgsCKtFV": {
            "date": "02:40 - 06/16/2019",
            "id": "211",
            "message": "At least until tomorrow. ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTnQvvC_VUxLsHjEg4": {
            "date": "22:41 - 06/15/2019",
            "id": "647u",
            "message": " will do! ",
            "user": "Steffany Padilla"
        },
        "-LhTnRZByZ9ACHdbo245": {
            "date": "02:41 - 06/16/2019",
            "id": "211",
            "message": "No problem. Let me bother you with just a few questions to fill up the medical history ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTnUq7qUvBd_E1nnYC": {
            "date": "22:41 - 06/15/2019",
            "id": "647u",
            "message": "ok",
            "user": "Steffany Padilla"
        },
        "-LhTnfYlEayfnSywuLUH": {
            "date": "02:42 - 06/16/2019",
            "id": "211",
            "message": "Could you tell me the name of the pet, breed, age, sex., please? ",
            "user": "Dr. Damian Levisman"
        },
        "-LhToNBUyeOqAKLyjVwH": {
            "date": "02:45 - 06/16/2019",
            "id": "211",
            "message": "Steffany, are you there? ",
            "user": "Dr. Damian Levisman"
        },
        "-LhToSwBByJdNppqHIC2": {
            "date": "22:45 - 06/15/2019",
            "id": "647u",
            "message": "peanut, dachshund, 11, male.",
            "user": "Steffany Padilla"
        },
        "-LhToXj20u5zDgCvNEzZ": {
            "date": "02:45 - 06/16/2019",
            "id": "211",
            "message": "Greta. Is he up to date with vaccines? ",
            "user": "Dr. Damian Levisman"
        },
        "-LhToZrtKmbjTJvzdvLB": {
            "date": "22:46 - 06/15/2019",
            "id": "647u",
            "message": "yes ",
            "user": "Steffany Padilla"
        },
        "-LhToffoYrC4CZ0NU4gH": {
            "date": "02:46 - 06/16/2019",
            "id": "211",
            "message": "What kind of food he eats? ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTokZEiO4cZCef_O9Z": {
            "date": "02:46 - 06/16/2019",
            "id": "211",
            "message": "And lastly if he's on flea meds and which one. ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTooC4YLZ5PVoQSGgm": {
            "date": "02:47 - 06/16/2019",
            "id": "211",
            "message": "Oh, sorry and if he's allergic to something ",
            "user": "Dr. Damian Levisman"
        },
        "-LhToxZ2XKgOGIxHSipu": {
            "date": "22:47 - 06/15/2019",
            "id": "647u",
            "message": "Purina benefit origina is the food we give him ",
            "user": "Steffany Padilla"
        },
        "-LhToz4AT6SGLkYXl2WO": {
            "date": "02:47 - 06/16/2019",
            "id": "211",
            "message": "Great",
            "user": "Dr. Damian Levisman"
        },
        "-LhTp8RXlWpg0W6VluDw": {
            "date": "02:48 - 06/16/2019",
            "id": "211",
            "message": "I only need the flea med information and if he's allergic to something and we are done. ",
            "user": "Dr. Damian Levisman"
        },
        "-LhTpEntv_f9oNEZ4Tm-": {
            "date": "22:49 - 06/15/2019",
            "id": "647u",
            "message": "for flees we use Hart’s ultra guard ",
            "user": "Steffany Padilla"
        },
        "-LhTpJvoyPfN0o1dSuv_": {
            "date": "22:49 - 06/15/2019",
            "id": "647u",
            "message": " he’s not ",
            "user": "Steffany Padilla"
        },
        "-LhTpOaaSV6Y5r1QhLhc": {
            "date": "02:49 - 06/16/2019",
            "id": "211",
            "message": "Wonderful. Hope he gets better and have a great night.! Bye",
            "user": "Dr. Damian Levisman"
        }
    }
]
export default class FirebaseModel {
    getChatDB() {
        let config_firebase = {
            apiKey: "AIzaSyCzg22B__LEBdkIF1ymt0Xqdl6TdaIBA3I",
            authDomain: "kwikvet-1377.firebaseapp.com",
            databaseURL: "https://kwikvet-1377.firebaseio.com/",
            projectId: "kwikvet-1377",
            storageBucket: "kwikvet-1377.appspot.com",
            messagingSenderId: "354469339949",
            appId: "1:354469339949:web:e5468c34a1481a9785a9a4",
            measurementId: "G-YY3DE3RWK8"
        }
        const app = firebase.initializeApp(config_firebase)
        return app.database().ref('ChatsRequests');
    }


}
// // init services
// const projectFirestore = firebase.firestore();
// const projectAuth = firebase.auth();
// const projectStorage = firebase.storage();
//
// export { projectFirestore, projectAuth, projectStorage,FirebaseModel };


// Example
// const chat = await this.$store.firebase.getChatDB();
// let tutorials = [];
// // await chat.once('value', function (snapshot) {
// //   snapshot.forEach(function (childSnapshot) {
// //     var key = childSnapshot.key;
// //     var data = childSnapshot.val();
// //     // ...
// //     tutorials.push({key: key, data: data});
// //   });
// // });
// await chat.orderByValue().limitToLast(2).on('value', (snapshot) => {
//   console.log("Snap",snapshot)
//   snapshot.forEach((data) => {
//     console.log("Data", data.val());
//   });
// });
// await chat.child('chat11271').orderByChild('date').limitToLast(2).on('value', snapshot => tutorials.push(snapshot.val()))
// console.log('Find',tutorials)