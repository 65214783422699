<template>
    <a-config-provider  style="height: 100%">
        <div>
            <Home v-if="auth"/>
            <Login v-else/>
        </div>
    </a-config-provider>
</template>

<script>
    import Login from './components/layout/templates/Login'
    import Home from './components/layout/templates/Home'

    export default {
        name: 'app',
        provide: function () {
            return {
                logout: this.logout,
                login: this.login,
                set_auth: this.set_auth
            }
        },
        data() {
            return {
                auth: this.$store.site.user != null ? true : false
            }
        },
        computed: {},

        methods: {
            logout() {
                this.auth = this.$store.site.logout()
                this.$router.push({name: 'index'})
            },
            login() {
                this.auth = this.$store.site.user != null ? 'logged' : false
                if (this.auth) {
                  location.reload()
                }
            },
            set_auth(value) {
                this.auth = value
            }
        },
        components: {Login, Home},
        mounted() {
            if (this.$route.name === 'reset_pass' || this.$route.name === 'init_pass') {
                this.auth = 'reset'
            }
        }
    }
</script>

<style scoped>
</style>
