<template>
  <section class="section">
    <div class="container mt-5">
      <Login_form :model="user"/>
    </div>
  </section>
</template>

<script>
import {required,} from 'vuelidate/lib/validators'
import Vue from "vue";
import Login_form from "../elements/login_form";

export default {
  name: 'Login',
  components: {Login_form},
  inject: {
    login: {
      default: () => {
      }
    }
  },

  validations: {
    user: {
      username: {
        required
      },
      password: {
        required
      },
    }
  },
  data() {
    return {
      user: {
        username: '',
        password: '',
        remember_me: false,
      },
      error: false,
      loading: false,
      message_error: 'Error en usuario y contraseña'
    }
  },
  /*
  :vobject="$v"
  */
  methods: {},
  beforeDestroy() {
    const recaptchaScripts = document.querySelectorAll('script')
    for (let i = 0; i < recaptchaScripts.length; i += 1) {
      recaptchaScripts[i].parentElement.removeChild(recaptchaScripts[i])
    }
  },
  async beforeMount() {

  },
  async mounted() {

  }
}
</script>

<style scoped>
/*.login.login-1 .login-aside .aside-img {
  min-height: 725px;
  min-width: 300px;
}

.login.login-1 .login-signin,
.login.login-1 .login-signup,
.login.login-1 .login-forgot {
  display: none;
}

.login.login-1.login-signin-on .login-signup {
  display: none;
}

.login.login-1.login-signin-on .login-signin {
  display: block;
}

.login.login-1.login-signin-on .login-forgot {
  display: none;
}

.login.login-1.login-signup-on .login-signup {
  display: block;
}

.login.login-1.login-signup-on .login-signin {
  display: none;
}

.login.login-1.login-signup-on .login-forgot {
  display: none;
}

.login.login-1.login-forgot-on .login-signup {
  display: none;
}

.login.login-1.login-forgot-on .login-signin {
  display: none;
}

.login.login-1.login-forgot-on .login-forgot {
  display: block;
}

@media (min-width: 992px) {
  .login.login-1 .login-aside {
    width: 100%;
    max-width: 750px;
  }

  .login.login-1 .login-content {
    width: 100%;
    max-width: 500px;
  }

  .login.login-1 .login-content .login-form {
    width: 100%;
    max-width: 450px;
  }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
  .login.login-1 .login-aside {
    width: 100%;
    max-width: 450px;
  }
}

@media (max-width: 991.98px) {
  .login.login-1 .login-content .login-form {
    width: 100%;
    max-width: 400px;
  }
}

@media (max-width: 575.98px) {
  .login.login-1 .aside-img {
    min-height: 300px !important;
    background-size: 400px;
  }

  .login.login-1 .login-content .login-form {
    width: 100%;
    max-width: 100%;
  }
}*/
</style>
