<template>
  <div ref="form_container" class="card position-relative mb-0" v-on:keyup.enter="save_model()">
    <spinner v-if="loading" />
    <div class="card-body p-0" >

      <tc-form
        :feedbacks="mb.statics('Orders').feedbacks"
        :vobject="$v"
        nested="orders"
        ref="form"
        class="form-row"
      >
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>_id<span class='required_field'>*</span></label>
        <tc-input placeholder='Type the value' name='_id' v-model="orders._id"></tc-input>
      </tc-form-item>
        <tc-form-item class="form-group mb-0 col-md-6 px-3">
          <label>Order_init_time</label>
          <div class="d-flex flex-row">
            <tc-date-picker name="order_init_time" v-model="orders.order_init_time"></tc-date-picker>
          </div>
        </tc-form-item>
        <tc-form-item class="form-group mb-0 col-md-6 px-3">
          <label>Is_complete</label>
          <div>
            <a-switch  v-model="orders.is_complete"/>
          </div>
        </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>Latitude</label>
        <tc-input placeholder='Type the value'   type_car='dec'  name='latitude' v-model="orders.latitude"></tc-input>
      </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>Longitude</label>
        <tc-input placeholder='Type the value'   type_car='dec'  name='longitude' v-model="orders.longitude"></tc-input>
      </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>Type</label>
        <tc-input placeholder='Type the value'   type_car='num'  name='type' v-model="orders.type"></tc-input>
      </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>Braintree</label>
        <tc-input placeholder='Type the value' name='braintree' v-model="orders.braintree"></tc-input>
      </tc-form-item>
      <tc-form-item class="form-group p-0 col-12">
        <label>Tz</label>
        <tc-input :text_area="true" size="large" name="tz" v-model="orders.tz"/>
      </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>Status</label>
        <tc-input placeholder='Type the value' name='status' v-model="orders.status"></tc-input>
      </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>Base_price</label>
        <tc-input placeholder='Type the value' name='base_price' v-model="orders.base_price"></tc-input>
      </tc-form-item>
      <tc-form-item class="form-group mb-0 col-md-6 px-3">
        <label>Vet_fee</label>
        <tc-input placeholder='Type the value'   type_car='dec'  name='vet_fee' v-model="orders.vet_fee"></tc-input>
      </tc-form-item>
        <tc-form-item class="form-group mb-0 col-md-6 px-3">
          <label>Clients</label>
          <div class="d-flex flex-row">
            <tc-autocomplete
              placeholder="Seleccione el Clients"
              name="client"
              ref="select_client"
              :successFeed="false"
              :defaultValue="orders.client"
              v-model="orders.client"
              :url="mb.statics('Clients').select_2_url"
            />
            <a-button type="dashed"
                      icon="plus"
                      class="dashed-primary rounded mt-1 ml-2"
                      @click="openModalCreateclient">
            </a-button>
          </div>
        </tc-form-item>

        <a-modal
          @cancel="showModalCreateclient = false"
          v-if="showModalCreateclient"
          class="modal-form"
          :title="'Añadir clients'"
          :visible="true"
          :header="null"
          :footer="null"
          :maskClosable="false"
        >
          <clients_form :model="null" :modal="true" @close_modal="clientAdded"/>
        </a-modal>
        <tc-form-item class="form-group mb-0 col-md-6 px-3">
          <label>Partners</label>
          <div class="d-flex flex-row">
            <tc-autocomplete
              placeholder="Seleccione el Partners"
              name="partner"
              ref="select_partner"
              :successFeed="false"
              :defaultValue="orders.partner"
              v-model="orders.partner"
              :url="mb.statics('Partners').select_2_url"
            />
            <a-button type="dashed"
                      icon="plus"
                      class="dashed-primary rounded mt-1 ml-2"
                      @click="openModalCreatepartner">
            </a-button>
          </div>
        </tc-form-item>

        <a-modal
          @cancel="showModalCreatepartner = false"
          v-if="showModalCreatepartner"
          class="modal-form"
          :title="'Añadir partners'"
          :visible="true"
          :header="null"
          :footer="null"
          :maskClosable="false"
        >
          <partners_form :model="null" :modal="true" @close_modal="partnerAdded"/>
        </a-modal>
      </tc-form>
    </div>
    <div class="card-footer p-0">
      <button :disabled="loading" class="btn btn-primary" @click="save_model()">{{button_text}}</button>
      <button v-if="!orders.get_id()" :disabled="loading" class="btn btn-primary" @click="save_model(true)">Añadir y nuevo</button>
      <button :disabled="loading" class="btn btn-danger" @click="cancel()">Cancel</button>
    </div>
  </div>
</template>

<script>
import Orders from "../../../../../entities/models/modules/managment/orders.model";
import * as utils from "../../../../../entities/utils/utils";
import * as mb from "../../../../../entities/models"
  import Clients_form from '../../clients/form/clients_form';
  import Partners_form from '../../partners/form/partners_form';

export default {
  name: "orders_form",
  inject: {
      close_modal: { default: ()=>{} },
      load_data: {default: () => {} }
  },
  props: {
    model: {
      type: Object,
      default: () => {}
    },
      modal: {
        type: Boolean,
        default: false
      },
    popoverPlacement: {
      type: String,
      default: "bottomLeft"
    }
  },
  validations: mb.statics('Orders').validations,
  data() {
    return {
      loading: false,
      mb,      // This property is for load static or instance class
      orders: mb.instance( 'Orders'),
      showModalCreateclient: false,
      clients_list: [],
      showModalCreatepartner: false,
      partners_list: [],
    };
  },
  computed: {
    ordersFeedbacks() {
      return mb.statics('Orders').feedbacks;
    },
    button_text() {
      return this.orders.get_id() ? "Actualizar" : "Añadir";
    },
  },

  mounted: function() {
    this.orders = mb.instance( 'Orders',this.model);

  },
  components: {

       Clients_form,
       Partners_form,
             },
  methods: {
      openModalCreateclient() {
        this.showModalCreateclient = true;
      },
      clientAdded(refresh) {
        this.showModalCreateclient = false;
        refresh?this.refreshclient():'';
      },
      async refreshclient() {
        this.loading = true;
        await this.$refs.select_client.load();
        this.loading = false;
      },
      openModalCreatepartner() {
        this.showModalCreatepartner = true;
      },
      partnerAdded(refresh) {
        this.showModalCreatepartner = false;
        refresh?this.refreshpartner():'';
      },
      async refreshpartner() {
        this.loading = true;
        await this.$refs.select_partner.load();
        this.loading = false;
      },
      cancel(){
        if (!this.model) {
          this.$emit('close_modal',false)
        } else {
        this.modal?this.close_modal(null,false):this.$router.push({name: 'orders_list'})
       }
      },
    save_model(and_new=false) {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const accion=this.orders.get_id() ? "actualizado" : "añadido";
        this.orders
          .save()
          .then((response) => {
            if(utils.process_response(response,accion)) {
              if (!this.model && !and_new && this.modal) {

                  this.$emit('close_modal',true)
                  return;
               }
                else {
                   !and_new?this.modal?this.close_modal(null,true):this.$router.push({name: 'orders_list'}):this.orders=mb.instance('Orders');               }
               this.load_data()
               this.$refs.form.vobject.$reset()

            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            utils.process_error(error);
          });
      }
    }
  }
};
</script>

<style scoped>
@import "orders_form.css";
</style>

